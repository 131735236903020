import { useEffect, useState } from 'react';
import { SubscriptionService } from 'services';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { setSubscription as setGlobalSubscription } from 'store/reducers/subscriptionSlice';

import { Subscription } from '@types';

const useSubscription  = (id?: string): Subscription  => {
  const globalSubscription  = useEnfluSelector(state => state.subscription);
  const dispatch = useEnfluDispatch();
  const [subscription, setSubscription] = useState<Subscription>(globalSubscription);

  useEffect(() => {
    const getSubscription = async () => {
      if (Object.keys(globalSubscription).length > 0 ) {
        setSubscription((prev) => ({ ...prev, ...globalSubscription }))
      } else {
        const userSubscription = await SubscriptionService.getCurrentSubscription();
        if (userSubscription) {
          setSubscription((prev: Subscription) => ({ ...prev, ...userSubscription }));
          dispatch(setGlobalSubscription(subscription));
        }
      }
    };

    getSubscription();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSubscription]);

  useEffect(() => {
    if (!id) return;
    if (id.length === 0) return;
    
    const getSubscriptionById = async (id: string) => {
      const subscription = await SubscriptionService.getSubscriptionById(id);
      if (subscription) {
        setSubscription((prev: Subscription) => ({ ...prev, ...subscription }));
        dispatch(setGlobalSubscription(subscription));
      }
    };

    getSubscriptionById(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return subscription;
};

export default useSubscription;
