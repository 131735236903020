// Styles
import './App.scss';
import './config/i18n/i18n';

import { useMemo, useReducer } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import theme from './config/theme/theme';
import router from './router/Router';
import { EnfluContext, initialState, reducer } from './store';
import { AuthProvider } from './store/auth.context';
import { ActionType } from './store/reducer';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextValue = useMemo(() => ({ 
    ...state, 
    setDraft: (draft: File | null) => dispatch({type: ActionType.SET_DRAFT, payload: draft}),
    dispatch 
  }), [state, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <EnfluContext.Provider value={contextValue}>
            <RouterProvider router={router()} />
        </EnfluContext.Provider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
