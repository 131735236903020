import { IconDownload, IconShare, IconUpgrade } from 'assets';
import { CustomTextField, theme as globalTheme, WhitePurpleButton } from 'config/theme';
import { blues } from 'config/theme/theme';
import { ShareVideo } from 'pages/common';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { addProject, selectProjects } from 'store/reducers/projectsSlice';
import { selectVideo } from 'store/reducers/videoSlice';
import * as yup from 'yup';

import { Project } from '@/@types';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha, Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';

const schema = (t: any) =>
  yup.object().shape({
    videoTitle: yup
      .string()
      .required(t("create.video-name-required"))
      .matches(/^[\w,-]+\.mp4$/, "Please enter a valid video filename"),
  });

const defaultValues = {
  videoTitle: "",
};

const FinalizedPage: FC = () => {
  const { t } = useTranslation();
  const projects = useEnfluSelector(selectProjects);
  const dispatch = useEnfluDispatch();
  const video = useEnfluSelector(selectVideo);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const { control, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  useEffect(() => {
    const newProject = {
      id: "1",
      title: "Project 1",
      description: "Project 1 description",
      thumbnail: "",
      status: "finalized",
      createdAt: new Date("2021-10-10"),
      updatedAt: new Date("2021-10-11"),
      deletedAt: new Date("2021-10-12"),
      tags: [{ value: "1", label: "Tag 1" }],
      video: null,
      audio : null,
      language: "en",
    } as Project;
    
    // check if the project is already in the store
    const projectExists = projects.some((project) => project.id === newProject.id);
    if (!projectExists) {
      dispatch(addProject(newProject));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video, projects]);

  const { isValid } = formState;

  return (
    <Box>
      <Typography variant="h5" className="text-center mt-4 mb-4 text-white">
        {t("signup.finalized")}
      </Typography>
      <Typography variant="body1" className="text-center mb-8 text-white">
        {t("signup.finalized-description")}
      </Typography>
      <Card className="mt-4 mb-8 rounded-2xl p-0">
        <CardContent className="p-0">
          {video && (
            <video
              autoPlay
              controls
              loop
              muted
              poster={video.poster}
              className="w-auto h-full object-cover lg:max-h-96 mx-auto"
            >
              <source src={video.url} type={video.type} />
            </video>
          )}
        </CardContent>
      </Card>
      <form
        name="uploadVideoForm"
        noValidate
        className="flex flex-col justify-center w-full my-4 sm:mb-0"
        onSubmit={() => {}}
      >
        <Controller
          name="videoTitle"
          control={control}
          render={({ field }) => (
            <Stack
              direction="row"
              spacing={2}
              className="flex mt-4 items-center"
            >
              <Typography
                variant="body1"
                className="text-lg w-[200px]"
                color="common.white"
              >
                {t("create.give-a-video-title")}
              </Typography>
              <CustomTextField
                {...field}
                className="text-slate-300 font-light grow w-full rounded-lg"
                placeholder={video?.name || t("create.video-name")}
                helperText={t("finalize.video-in-mp4")}
                autoFocus
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {field.value?.length && isValid ? (
                        <CheckCircleIcon style={{ color: green[500] }} />
                      ) : null}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  color: "text.primary",
                  bgcolor: alpha(globalTheme.blues[200], 0.2),
                  border: "none",
                  "& input": {
                    height: "100%",
                  },
                  "& input::placeholder": {
                    color: "white",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
              />
            </Stack>
          )}
        />
      </form>
      <Stack
        direction="row"
        spacing={2}
        className="flex mt-4 items-center mx-auto justify-center"
      >
        <Button
          variant="outlined"
          startIcon={<IconDownload />}
          className="w-48 text-white border-white rounded-xl"
          onClick={() => {}}
        >
          {t("create.download")}
        </Button>
        <Button
          variant="outlined"
          startIcon={<IconShare />}
          className="w-48 text-white border-white rounded-xl"
          onClick={() => setOpenPopup(true)}
        >
          {t("create.share")}
        </Button>
      </Stack>
      <Card
        className="mt-12 max-w-lg mx-auto mb-4 rounded-xl"
        sx={{ backgroundColor: blues[500] }}
      >
        <CardContent className="grid grid-cols-3">
          <IconUpgrade className="h-40 w-6/12 mx-auto" />
          <Box className="col-span-2 flex flex-col items-start">
            <Typography
              variant="h5"
              className="text-center mt-4 mb-4 text-white"
            >
              {t("create.create-more")}
            </Typography>
            <Typography variant="body1" className="text-center mb-8 text-white">
              {t("create.create-more-description")}
            </Typography>
            <WhitePurpleButton color="primary" variant="contained">
              {t("menu.upgrade-now")}
            </WhitePurpleButton>
          </Box>
        </CardContent>
      </Card>
      <ShareVideo open={openPopup} setOpen={setOpenPopup} video={video} />
    </Box>
  );
};

export default FinalizedPage;
