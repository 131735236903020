import { useAuth } from 'hooks';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth : FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();
  
  if (!auth.token) {
    return <Navigate to="/signin" state={{ from: location.pathname }} />;
  }
  return <>{children}</>;
};

export default RequireAuth;