import { ProjectCard } from 'pages/common';
import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Project } from '@types';

interface FinalizedProjectsPageProps {
  projects?: Project[];
}

const FinalizedProjectsPage: FC<FinalizedProjectsPageProps> = ({
  projects,
}) => {
  return (
    <Box className="flex gap-4 mt-10 mb-32">
      {projects?.length === 0 && (
        <Box className="text-center w-full text-white">
          <Typography variant="body2">No finalized projects yet</Typography>
        </Box>
      )}
      {projects?.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </Box>
  );
};

export default FinalizedProjectsPage;
