// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

import {
    CreateAccount_2, ExpandedMenu_3, LanguageSelected_6, LinkVerified_4, LoadingPreview_5, Login_1,
    PreviewVideo_8, Share_9
} from 'assets';
import { gradients } from 'config/theme/theme';
import Footer from 'pages/common/footer/Footer';
import ImageShow from 'pages/common/image-show/ImageShow';
import CardVideo from 'pages/common/video/CardVideo';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { Image, Video } from '@types';

import Creators from './creators/Creators';
import EnfluInUse from './enflu-in-use/EnfluInUse';
import ExpandReach from './expand-reach/ExpandReach';
import Features from './features/Features';
import HomeHeader from './header/HomeHeader';
import HomeStepsList from './steps/HomeStepsList';
import MainTitle from './titles/MainTitle';

const enfluVideo: Video = {
  name: "Enflu",
  poster: "",
  url: process.env.REACT_APP_PUBLIC_URL + "/videos/Enflu.mp4",
  type: "video/mp4",
  size: 23 * 1024 * 1024,
  lastModified: new Date().getTime(),
  webkitRelativePath: "",
  arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
  slice: () => new Blob(),
  stream: () => new ReadableStream(),
  text: () => Promise.resolve("File content as string"),
};

const stepsImageShow: Image[] = [
  {
    src: Login_1,
    alt: "Login",
  },
  {
    src: CreateAccount_2,
    alt: "Create Account",
  },
  {
    src: ExpandedMenu_3,
    alt: "Expanded Menu",
  },
  {
    src: LinkVerified_4,
    alt: "Link Verified",
  },
  {
    src: LoadingPreview_5,
    alt: "Loading Preview",
  },
  {
    src: LanguageSelected_6,
    alt: "Language Selected",
  },
  {
    src: PreviewVideo_8,
    alt: "Preview Video",
  },
  {
    src: Share_9,
    alt: "Share",
  },
];

const Home: FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();

  return (
    <Box className="w-full h-full">
      {!isMobile && <HomeHeader navBarSelectionIndex={-1} />}
      <MainTitle />
      <Box className="bg-enflu-grey-400 pb-32">
        <CardVideo
          video={enfluVideo}
          className="border-white w-11/12 md:w-auto -translate-x-1/2"
          preload="metadata"
          controls={false}
        />
        <Creators />
      </Box>
      <Box sx={{ background: gradients.body }} className="pb-32">
        <Box className="container mx-auto pt-8 md:pt-32 flex flex-col sm:grid sm:grid-cols-2 items-center max-w-[90%] md:max-w-auto md:justify-around">
          <Typography
            variant="h3"
            className={`font-inter font-semibold w-full md:w-2/3 ${isMobile ? "py-8 text-center text-4xl" : ""} md:ml-[20%]`}
          >
            <span className="text-white inline-block md:block">{t("home.from-local")}</span>
            {isMobile && ' '}
            <span className="text-white inline-block md:block">{t("home.to-global")}</span>
            {isMobile && <br />}
            <span className="text-enflu-purple-100">{t("home.3-steps")}</span>
          </Typography>
          <Box className="overflow-hidden rounded-lg w-full md:-ml-[10%]">
            <ImageShow
              images={stepsImageShow}
              className="card-video-blue"
              containerClassName="mt-2 mx-auto"
            />
          </Box>
        </Box>
        <HomeStepsList />
        <EnfluInUse />
      </Box>
      <Box className="bg-enflu-grey-400">
        <Features />
        <ExpandReach />
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
