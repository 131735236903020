import axios from "axios";

import { FacebookUser, GoogleUser, User, UserSession } from "@types";

const API_URL = process.env.REACT_APP_API_URL;

const AuthService = {
  checkUserByEmail: async (email: string): Promise<number> => {
    try {
      const response = await axios.get(`${API_URL}/user/${email}`);

      return response.status;
    } catch (error) {
      console.error("Error checking user with email", error);
      throw error;
    }
  },

  signupWithGoogle: async (idToken: string): Promise<GoogleUser> => {
    try {
      const response = await axios.post(`${API_URL}/signup/google`, {
        idToken,
      });
      return response.data;
    } catch (error) {
      console.error("Error signing up with Google", error);
      throw error;
    }
  },

  signupWithFacebook: async (accessToken: string): Promise<FacebookUser> => {
    try {
      const response = await axios.post(`${API_URL}/signup/facebook`, {
        accessToken,
      });
      return response.data;
    } catch (error) {
      console.error("Error signing up with Facebook", error);
      throw error;
    }
  },

  signup: async (user: User): Promise<User> => {
    try {
      const response = await axios.post(`${API_URL}/signup`, user);
      if (response.status !== 201) {
        throw new Error("Error signing up");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing up", error);
      throw error;
    }
  },

  signin: async (credentials: {
    email: string;
    password: string;
  }): Promise<UserSession> => {
    try {
      const response = await axios.post(`${API_URL}/signin`, credentials);
      if (response.status !== 200) {
        throw new Error("Error signing in");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in", error);
      throw error;
    }
  },
};

export default AuthService;
