import { EnfluLogo } from 'assets';
import { gradients } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Typography, useMediaQuery } from '@mui/material';

import HomeHeader from '../header/HomeHeader';

const MainTitle: FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  return (
    <Box
      className="pt-20 md:pt-60 px-10 relative w-full h-[900px] md:h-[800px]"
      sx={{ background: gradients.home }}
    >
      {isMobile && (
      <Box className="pb-8 gap-y-8 flex flex-col">
        <EnfluLogo className="w-32 h-32 mx-auto" />
        <HomeHeader navBarSelectionIndex={-1} />
      </Box>
      )}
      <Box className="flex flex-col mx-auto text-center md:max-w-3xl justify-center gap-y-8">
        <Typography
          variant="h1"
          className="text-[1.5rem] md:text-[2rem] font-medium md:font-normal font-inter text-white"
        >
          {t("home.title")}
        </Typography>
        <Typography
          variant="h2"
          className="text-lg md:text-xl font-semibold font-inter text-white"
        >
          {t("home.subtitle")}
        </Typography>
        <Button
          component={Link}
          variant="contained"
          className="rounded-lg normal-case font-medium text-white text-lg hover:shadow-lg hover:shadow-white/10 md:w-[28%] py-2"
          sx={{ background: gradients.blues }}
          to="/signin"
        >
          {t("home.get-started-for-free")}
        </Button>
      </Box>
    </Box>
  );
};

export default MainTitle;
