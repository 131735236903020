import { CustomTextField, theme as globalTheme } from 'config/theme';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, IconButton, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import { UploaderStatus } from '@types';

const schema = yup.object().shape({
  videoUrl: yup.string().url("You must enter a valid URL").notRequired(),
});

const defaultValues = {
  videoUrl: "",
};

interface VideoUrlLoaderProps {
  setStatus: (status: UploaderStatus) => void;
}

const VideoUrlLoader: FC<VideoUrlLoaderProps> = ({ setStatus }) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = formState;
  const handleChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    if (target.value && isValid) {
      setStatus(UploaderStatus.success);
    } else {
      setStatus(UploaderStatus.ready);
    }
  };

  const onSubmit = (data: any) => {};

  return (
    <form
      name="uploadVideoForm"
      noValidate
      className="flex flex-col justify-center w-full sm:mb-0 lg:w-[588px] mx-auto"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="videoUrl"
        control={control}
        render={({ field }) => (
          <Stack direction="row" spacing={2} className="flex mt-4 items-center">
            <Typography
              variant="body1"
              className="text-base font-bold w-[200px]"
              color="common.white"
            >
              {t("create.or-paste-a-link-here")}
            </Typography>
            <CustomTextField
              {...field}
              className="text-slate-300 font-light grow w-full rounded-lg"
              placeholder="https://"
              autoFocus
              type="url"
              error={!!errors.videoUrl}
              helperText={errors?.videoUrl?.message}
              onChange={(e) => {
                field.onChange(e.target.value);
                handleChange(e);
              }}
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {field.value?.length && isValid ? (
                      <CheckCircleIcon style={{ color: green[500] }} />
                    ) : null}
                    {field.value && (
                      <IconButton
                        edge="end"
                        onClick={() => {
                          field.onChange(""); // Clear the field
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                color: "text.primary",
                bgcolor: alpha(globalTheme.blues[200], 0.2),
                border: "none",
                "& input": {
                  height: "100%",
                },
                "& input::placeholder": {
                  color: "white",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
            />
          </Stack>
        )}
      />
    </form>
  );
};

export default VideoUrlLoader;
