import { FC } from 'react';
import SwiperCore from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Box } from '@mui/material';
import { Image } from '@types';

interface ImageShowProps {
  containerClassName?: string;
  className?: string;
  images: Image[];
}

SwiperCore.use([Autoplay, Navigation]);

const ImageShow : FC<ImageShowProps> = ({ images, containerClassName = '', className = '' }) => (
  <Box className={`w-full h-full ${containerClassName}`}>
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      loop
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      className={className}
      modules={[Navigation, Autoplay]}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.src} alt={image.alt} className="w-full h-full object-cover" />
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
);


export default ImageShow;