import { EnfluLogo } from 'assets';
import { gradients } from 'config/theme/theme';
import Footer from 'pages/common/footer/Footer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Paper, Stack, Typography } from '@mui/material';

const Page404: FC = () => {
  const { t } = useTranslation();
  return (
    <Box 
    sx={{ background: gradients.home }}
      className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full">
      <Box className="pt-20 md:pt-8">
        <Box className="pb-8 gap-y-8 flex flex-col">
          <EnfluLogo className="w-32 h-32 mx-auto" />
        </Box>
      </Box>
      <Box className="bg-none p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto">
        <Typography
          variant="h2"
          className="font-inter text-white text-center mt-2 text-4xl md:text-3xl font-semibold tracking-tight leading-tight"
        >
          {t("page404.title")}
        </Typography>
      </Box>
      <Paper className="bg-none bg-enflu-grey-400 p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl">
        <Stack direction="column">
          <Typography
            variant="body1"
            className="text-center text-white font-light"
          >
            {t("page404.description")}
          </Typography>
          <Stack direction="row" spacing={2} className="my-6 justify-center">
            <Button
              variant="outlined"
              className="transition duration-200 text-white border-white"
              color="secondary"
              component={Link}
              to="/"
            >
              {t("general.go-to-home")}
            </Button>
          </Stack>
        </Stack>
      </Paper>
      <Footer className="w-screen mt-16"/>
    </Box>
  );
};

export default Page404;
