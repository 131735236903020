import axios, { AxiosResponse } from 'axios';
import { retry } from 'utils';

import { LipSyncResponse, Video, VoiceCloningDubResponse } from '@types';

const API_URL = process.env.REACT_APP_API_URL;

const VideoService = {
  getVideoById: async (
    videoId: string,
    token: string
  ): Promise<Video | null> => {
    try {
      const response = await axios.get(`${API_URL}/video/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error getting video", error);
      return null;
    }
  },

  /**
   * @desc Uploads a video to the server and starts the voice cloning process
   * @param {FormData} formData
   * @returns {Promise<VoiceCloningDubResponse>}
   */
  startVoiceCloning: async (
    formData: FormData,
    token: string
  ): Promise<AxiosResponse<VoiceCloningDubResponse>> => {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/dub`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error uploading video", error);
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  },

  getVoiceCloningVideo: async (
    dubbingId: string,
    languageId: string,
    token: string,
    retries = 5
  ): Promise<AxiosResponse<Blob | null>> => {
    return retry(async () => {
      const response = await axios.get(
        `${API_URL}/dub/${dubbingId}/${languageId}`,
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "video/mp4",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    }, retries);
  },

  convertVideoToAudio: async (
    videoFile: File,
    token: string
  ): Promise<AxiosResponse<Blob | null>> => {
    try {
      const formData = new FormData();
      formData.append("file", videoFile);
      formData.append("convertType", "mp3");

      const response = await axios.post(
        `${API_URL}/video-converter`,
        formData,
        {
          responseType: "blob", // Expecting the response to be a blob
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error converting video to audio:", error);
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  },

  startLipSync: async (
    formData: FormData,
    token: string
  ): Promise<AxiosResponse<LipSyncResponse>> => {
    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/lip-sync`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error("Error starting lip sync", error);
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  },

  getLipSyncVideo: async (
    lipSyncId: string,
    token: string,
    retries = 5
  ): Promise<AxiosResponse<Blob | null>> => {
    return retry(async () => {
      const response = await axios.get(`${API_URL}/lip-sync/${lipSyncId}`, {
        responseType: "arraybuffer",
        headers: {
          Accept: "video/mp4",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    }, retries);
  },
};

export default VideoService;
