import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Video } from '@types';

import type { EnfluState } from "../store";

const initialState: Video = {
  name: "Example.mp4",
  type: "video/mp4",
  poster: "https://assets.codepen.io/6093409/river.jpg",
  duration: 120,
  url: "https://assets.codepen.io/6093409/river.mp4",
  size: 23 * 1024 * 1024,
  lastModified: new Date().getTime(),
  webkitRelativePath: "",
  arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
  slice: () => new Blob(),
  stream: () => new ReadableStream(),
  text: () => Promise.resolve("File content as string"),
};

export const videoSlice = createSlice({
  name: "video",
  initialState: ({} as Video) || null,
  reducers: {
    setVideo: (state, action: PayloadAction<Video>) => {
      return { ...state, ...action.payload };
    },
    clearVideo: () => initialState,
  },
});

export const { setVideo, clearVideo } = videoSlice.actions;
export const selectVideo = (state: EnfluState) => state.video;
export default videoSlice.reducer;
