import { IconArrowLeft } from 'assets';
import { greyIsh } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Modal, Stack, Typography } from '@mui/material';

interface ReturnStartCreateProps {
  open: boolean;
}

export const ReturnStartCreate : FC<ReturnStartCreateProps> = ({ open }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      aria-labelledby="return-start-create-title"
      aria-describedby="return-start-create-description"
    >
      <>
        <Box className="fixed h-full w-full opacity-90" sx={{ backgroundColor: greyIsh[400] }} />
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/12 p-4">
          <Typography id="summary-review-title" variant="h6" component="h2" color="text.bluelights" className="text-center">
            {t('return-start.description')}
          </Typography>
          <Stack direction="row" spacing={2} className="flex mt-4 items-center mx-auto justify-center">
            <Button
              variant='outlined'
              startIcon={<IconArrowLeft />}
              className='w-48 text-white border-white rounded-xl'
              component={Link}
              to="/create/upload"
            >
              {t('general.go-back')}
            </Button>
          </Stack>
        </Box>
      </>
    </Modal>
  )
};