import { Language, Subscription, User, Video } from '@types';

interface Product {
  language?: Language;
  video?: Video;
}

export type EnfluContextType = {
  user: User;
  dbconfig: any;
  subscription: Subscription;
  product: Product;
  orderForm: any;
  draft: File | null;
  setUser: (user: User) => void;
  setDbconfig: (dbconfig: any) => void;
  setSubscription: (subscription: Subscription) => void;
  setProduct: (product: Product) => void;
  setOrderForm: (orderForm: any) => void;
  setDraft: (draft: File | null) => void;
};

export const initialState = {
  user: {
    guest: true,
  } as User,
  dbconfig: null,
  subscription: {},
  product: {},
  orderForm: {},
  draft: null,
  setUser: () => {},
  setDbconfig: () => {},
  setSubscription: () => {},
  setProduct: () => {},
  setOrderForm: () => {},
  setDraft: () => {},
} as EnfluContextType;
