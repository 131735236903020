import { blues } from 'config/theme/theme';
import { CreateActions, SummaryReview } from 'pages/common';
import { ReturnStartCreate, SaveAsDraft } from 'pages/common/modals';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { setTargetLanguage } from 'store/reducers/languageSlice';
import { selectVideo } from 'store/reducers/videoSlice';
import { fromBytesToMB } from 'utils';

import { alpha, Box, Card, Grid, Stack, Typography } from '@mui/material';
import { Language } from '@types';

import LanguageCard from './LanguageCard';
// Mock
import Languages from './LanguageMock';

const LanguagePage : FC = () => {
  const { t } = useTranslation();
  const dispatch = useEnfluDispatch();
  const video  = useEnfluSelector(selectVideo);
  const [langG, setLangG] = useState<Language>({} as Language);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openSaveAsDraft, setOpenSaveAsDraft] = useState<boolean>(false);

  const handleLanguageClick = (lang: Language) => setLangG(lang);

  return (
    <Box>
      <Stack direction="row" spacing={2} className="mt-16 mb-8 items-center ml-8">
        <Typography variant="body1" className="text-white">
          {t('create.preview-page-title')}
        </Typography>
        <Card className="rounded-lg p-4" sx={{ color: blues[100], backgroundColor: alpha(blues[100], .4) }} >
          {video.name} ({fromBytesToMB(video.size ?? 0)} MB)
        </Card>
      </Stack>
      <Typography variant="body1" className="text-white text-left mb-4 ml-8">
        {t('create.to-be-translated')}
      </Typography>
      <Grid container className="max-w-3xl mx-auto">
        {Languages.map((lang : Language, index : number) => (
          <Grid item xs={4} key={index}>
            <LanguageCard {...lang} selected={lang.code === langG.code} onClick={handleLanguageClick} />
          </Grid>
        ))}
      </Grid>
      <CreateActions
        hasBackButton
        hasNextButton
        nextButtonProps={
          {
            buttonStr: t('create.next'),
            openPopup: true,
            disabled: Object.keys(langG).length === 0 || Object.keys(video).length === 0,
            onClick: (event: React.MouseEvent<HTMLElement>) => {
              setOpenPopup(true);
              dispatch(setTargetLanguage(langG));
            },
          }
        }
        backButtonProps={
          {
            buttonStr: t('create.save-to-draft'),
            openPopup: true,
            onClick: () => setOpenSaveAsDraft(true),
            variant: "outlined"
          }
        }
      />
      <SummaryReview 
        open={openPopup}
        setOpen={setOpenPopup}
        setOpenDraft={setOpenSaveAsDraft}
        file={video}
        language={langG}
      />
      <ReturnStartCreate open={Object.keys(video).length === 0} />
      <SaveAsDraft open={openSaveAsDraft} setOpen={setOpenSaveAsDraft} video={video} />
    </Box>
  )
};

export default LanguagePage;