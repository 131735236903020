import { IconClose, IconSuccess, IconUpload } from 'assets';
import { blues, gradients } from 'config/theme/theme';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import EnfluContext from 'store/enflu.context';
import { useEnfluDispatch } from 'store/hooks';
import { setVideo } from 'store/reducers/videoSlice';
import { fromBytesToMB } from 'utils';

import {
    alpha, Box, Button, IconButton, LinearProgress, Stack, Tooltip, Typography
} from '@mui/material';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import { UploaderStatus, Video } from '@types';

interface UploaderPropsTypes {
  file?: Video;
  error?: string;
  status?: UploaderStatus,
  setStatus?: (status: UploaderStatus) => void;
  onClickAfterError?: () => void;
  progress?: number;
  loop?: boolean;
  disabledDropzone?: boolean;
}


const UploadVideoPage = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '20rem',
  borderRadius: 12,
  backgroundImage: "none",
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
  '& .icon': {
    fontSize: '48px',
    marginBottom: theme.spacing(2),
  },
  '& .progress': {
    marginTop: theme.spacing(2),
  },
}));


export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box className="w-7/12 flex items-center gap-x-4">
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.bluelights" className="font-medium">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
      <Box className="w-full rounded-lg h-5 relative z-1" sx={{ backgroundColor: alpha(blues[200], 0.2) }}>
          <LinearProgress 
            {...props}
            variant="determinate" 
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[97%] rounded-lg h-3"
            sx={{
              '&':{
                backgroundColor: alpha(blues[200], 0.2)
              },
              '& .MuiLinearProgress-barColorPrimary' : {
                background: gradients.full
              }
             }}
          />
      </Box>
    </Box>
  );
}


const Uploader : FC<UploaderPropsTypes> = ({ file, error, status, setStatus, progress: _progress = 0, loop = true, disabledDropzone = false, onClickAfterError = () => {} }) => {
  const dispatch = useEnfluDispatch();
  const { setDraft } = useContext(EnfluContext);
  const [progress, setProgress] = useState(_progress);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!loop) {
      setProgress(_progress);
    } else {
      const timer = setInterval(() => {
        setProgress((prevStatus) => prevStatus >= 100 ? 10 : prevStatus + 10);
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [loop, _progress]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      setStatus && setStatus(UploaderStatus.error);
      setErrorMessage(t('upload-video.no-file-selected'));
      return;
    }

    // We only accept one video file
    if (acceptedFiles[0].type.indexOf('video') === -1) {
      setStatus && setStatus(UploaderStatus.error);
      setErrorMessage(t('upload-video.file-not-video'));
      return;
    }

    // We only accept video files less than 10MB
    if (acceptedFiles[0].size > 10 * 1024 * 1024) {
      setStatus && setStatus(UploaderStatus.error);
      setErrorMessage(t('upload-video.file-too-large'));
      return;
    }
    
    setStatus && setStatus(UploaderStatus.loading);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    setStatus && setStatus(UploaderStatus.success);
    const videoObj = {
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
        type: acceptedFiles[0].type,
        lastModified: acceptedFiles[0].lastModified,
    } as Video;
    setErrorMessage('');
    setDraft(acceptedFiles[0] as File);
    dispatch(setVideo(videoObj));

    // eslint-disable-next-line
  }, []);

  const handleRemoveFile = () => {
    dispatch(setVideo({} as Video));
    setStatus && setStatus(UploaderStatus.ready);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const renderReadyStatus = <>
    <IconUpload className="icon h-20 w-auto opacity-20" />
    <Typography variant="body1" className="text-white text-xl">{t('upload-video.upload-video-audio-here')}</Typography>
    <Typography variant="caption" className="mt-4 text-slate-300">{t('upload-video.extensions')}</Typography>
  </>;

  const renderErrorStatus = <Stack direction="column" spacing={2}>
    <Typography variant='h5' color="common.white">{t('create.upload-error')}</Typography>
    <Typography variant='body1' color="error.main">{errorMessage.length ? errorMessage : error}</Typography>
    <Button variant="outlined" size="medium" className="text-white border-white" onClick={() => onClickAfterError()}>{t('general.try-again')}</Button>
  </Stack>;

  const renderSuccessStatus = 
    <Box className="relative w-full h-full flex flex-col justify-center items-center gap-y-4">
      <Tooltip title={t('upload-video.remove-video')} placement="left">
        <IconButton aria-label="close" className="absolute top-5 right-10" onClick={handleRemoveFile} >
          <IconClose />
        </IconButton>
      </Tooltip>
      <Box className="relative rounded-full h-20 w-20" sx={{ backgroundColor: blues[100] }}>
        <IconSuccess className="icon h-16 w-auto absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </Box>
      <Typography variant="body1" className="text-white text-xl">{t('upload-video.upload-was-successful')}</Typography>
      <Typography variant="caption" className="mt-4 text-slate-300">{file?.name} ({fromBytesToMB(file?.size ?? 0)} MB)</Typography>
    </Box>;

  const renderLoadingStatus = <LinearProgressWithLabel value={progress} />;

  const renderContent = () => {
    switch(status) {
      case UploaderStatus.ready:
        return renderReadyStatus;
      case UploaderStatus.loading:
        return renderLoadingStatus;
      case UploaderStatus.success:
        return renderSuccessStatus;
      case UploaderStatus.error:
        return renderErrorStatus;
      default:
        return renderReadyStatus;
    }
  }

  return (
    <UploadVideoPage {...(status !== UploaderStatus.success && !disabledDropzone ? getRootProps() : {})}>
      {status !== UploaderStatus.success && <input {...getInputProps()} />}
      {renderContent()}
    </UploadVideoPage>
  )
}

export default Uploader;