import { CustomTextField } from 'config/theme';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
    Avatar, AvatarGroup, Box, Button, CircularProgress, Paper, Typography
} from '@mui/material';

type FormFields = {
  email: string;
};

const schema : (t: TFunction) => yup.ObjectSchema<FormFields> = (t : TFunction) => yup.object().shape({
  email: yup.string().email(t('auth.yup-email-validation')).required(t('auth.yup-email-required')),
});

const defaultValues = {
  email: "",
};

const ComingSoonPage = () => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  const { errors } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);


  const onSubmit = async (data: FormFields) => {
    setIsSubmitting(true);
    // fake a delay
    setTimeout(() => {
      setIsSubmitting(false);
      reset(defaultValues);
    }, 1500);
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
      <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
        <div className="w-full sm:w-auto py-32 px-8 ltr:border-r-1 rtl:border-l-1 max-w-96">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <img
              className="w-48"
              src="assets/images/logo/educadao-logo.png"
              alt="logo"
            />

            <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
              {t('signup.almost-there')}
            </Typography>
            <Typography className="mt-2">
              {t('coming-soon.subtitle1')}
            </Typography>

            <form
              name="comingSoonForm"
              noValidate
              className="flex flex-col justify-center w-full mt-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="email"
                control={control}
                render={({ field } : { field: FieldValues }) => (
                  <CustomTextField
                    {...field}
                    label={t('signup.email')}
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Button
                variant="contained"
                color="primary"
                className="w-full mt-4 text-white"
                aria-label="Register"
                type="submit"
                size="large"
              >
                {isSubmitting ? (
                <CircularProgress size={24} className="mx-4 text-white" />
              ) : (
                t('coming-soon.notify-me-when-you-launch')
              )}
              </Button>

              <Typography
                variant="caption"
                className="mt-8 text-md font-medium"
              >
                {t('coming-soon.bottom-caption')}
              </Typography>
            </form>
          </div>
        </div>

        <Box
          className="relative hidden md:flex flex-auto items-center justify-center h-full py-64 px-32 overflow-hidden"
          sx={{ backgroundColor: "primary.main" }}
        >

          <Box className="z-10 relative w-full max-w-xl">
            <Typography variant="h1" className="font-light leading-none text-gray-100">
              Welcome to
              Enflu
            </Typography>

            <div className="flex items-center mt-8">
              <AvatarGroup
                sx={{
                  "& .MuiAvatar-root": {
                    backgroundColor: "white",
                    borderColor: "primary.main",
                  },
                }}
              >
                <Avatar sx={{ color: "primary.main" }} />
                <Avatar sx={{ color: "primary.main" }}/>
                <Avatar sx={{ color: "primary.main" }} />
                <Avatar sx={{ color: "primary.main" }} />
              </AvatarGroup>

              <Typography variant="body2" className="ml-16 font-medium tracking-tight text-white">
                More than X people joined us, it's your turn
              </Typography>
          </div>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default ComingSoonPage;
