import { IconArrowLeft } from 'assets';
import { theme } from 'config/theme';
import { gradients } from 'config/theme/theme';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { alpha, Button, CircularProgress, Stack } from '@mui/material';

interface BackButtonProps {
  url?: string;
  variant?: "text" | "outlined";
  buttonStr?: string;
  className?: string;
  hasArrow?: boolean;
  openPopup?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const BackButton: FC<BackButtonProps> = ({
  url,
  hasArrow,
  variant,
  buttonStr,
  className,
  openPopup,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variant={variant || "text"}
      size="large"
      component={openPopup ? "button" : Link}
      startIcon={hasArrow && <IconArrowLeft />}
      to={openPopup ? "#" : url || "/"}
      className={`text-white capitalize rounded-xl ${className}`}
      onClick={onClick}
    >
      {t(buttonStr || "create.back")}
    </Button>
  );
};

interface NextButtonProps {
  disabled?: boolean;
  buttonStr?: string;
  url?: string;
  className?: string;
  openPopup?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  type?: "submit" | "button";
  isLoading?: boolean;
}

export const NextButton: FC<NextButtonProps> = ({
  disabled,
  buttonStr,
  url,
  className,
  onClick,
  openPopup = false,
  type = "button",
  isLoading = false,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      size="large"
      className={`text-white px-4 rounded-lg text-lg font-normal capitalize ${className} ${disabled ? "opacity-50" : ""}`}
      component={openPopup ? "button" : Link}
      to={openPopup ? "#" : url || "/"}
      onClick={onClick}
      disabled={disabled}
      sx={{
        background: gradients.blues,
        boxShadow: `0 0 20px 0px ${alpha(theme.blues[100], 0.3)}`,
      }}
      type={type}
    >
      {isLoading ? (
        <CircularProgress size={24} className="mx-4" color="secondary" />
      ) : (
        t(buttonStr || "create.proceed")
      )}
    </Button>
  );
};

interface CreateActionsProps {
  hasBackButton?: boolean;
  backButtonProps?: BackButtonProps;
  hasNextButton?: boolean;
  nextButtonProps?: NextButtonProps;
}

const CreateActions: FC<CreateActionsProps> = ({
  hasBackButton,
  hasNextButton,
  nextButtonProps,
  backButtonProps,
}) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent={"center"}
      className="mt-4 mb-16"
    >
      {hasBackButton && <BackButton {...backButtonProps} />}
      {hasNextButton && <NextButton {...nextButtonProps} />}
    </Stack>
  );
};

export default CreateActions;
