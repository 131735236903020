import { IconClose, IconProfile, IconSuccess } from "assets";
import { TFunction } from "i18next";
import React from "react";

interface Feature {
  icon: React.ComponentType<any>;
  title: string;
  hasInfo?: boolean;
  info?: string;
}

interface PlanCardTypes {
  highlight?: boolean;
  type: string;
  title: string;
  buttonText: string;
  features: Feature[];
  notIncludes: Feature[];
  billed?: string;
}

export const monthlyPlans = (t: TFunction): PlanCardTypes[] => {
  return [
    {
      highlight: true,
      type: t("pricing.creator.title"),
      title: t("pricing.creator.price"),
      buttonText: t("pricing.creator.button-text"),
      features: [
        {
          icon: IconProfile,
          title: t("pricing.creator.user-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-1"),
          hasInfo: true,
          info: t("pricing.creator.tooltip-1"),
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-4"),
          hasInfo: true,
          info: t("pricing.creator.tooltip-2"),
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-5"),
          hasInfo: false,
        },
      ],
      notIncludes: [],
    },
    {
      highlight: false,
      type: t("pricing.starter.title"),
      title: t("pricing.starter.price"),
      buttonText: t("pricing.starter.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-5"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.starter.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.starter.item-6"),
          hasInfo: false,
        },
      ],
      billed: t("pricing.starter.caption"),
    },
    {
      highlight: false,
      type: t("pricing.starter-plus.title"),
      title: t("pricing.starter-plus.price"),
      buttonText: t("pricing.starter-plus.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-4"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.starter-plus.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.starter-plus.item-6"),
          hasInfo: false,
        },
      ],
      billed: t("pricing.starter-plus.caption"),
    },
    {
      highlight: false,
      type: t("pricing.free.title"),
      title: t("pricing.free.price"),
      buttonText: t("pricing.free.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.free.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-4"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.free.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.free.item-6"),
          hasInfo: false,
        },
      ],
    },
  ];
};

export const yearlyPlans = (t: TFunction): PlanCardTypes[] => {
  return [
    {
      highlight: true,
      type: t("pricing.creator.title"),
      title: t("pricing.creator.price"),
      buttonText: t("pricing.creator.button-text"),
      features: [
        {
          icon: IconProfile,
          title: t("pricing.creator.user-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-1"),
          hasInfo: true,
          info: t("pricing.creator.tooltip-1"),
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-4"),
          hasInfo: true,
          info: t("pricing.creator.tooltip-2"),
        },
        {
          icon: IconSuccess,
          title: t("pricing.creator.item-5"),
          hasInfo: false,
        },
      ],
      notIncludes: [],
    },
    {
      highlight: false,
      type: t("pricing.starter.title"),
      title: t("pricing.starter.yearly-price"),
      buttonText: t("pricing.starter.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter.item-5"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.starter.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.starter.item-6"),
          hasInfo: false,
        },
      ],
      billed: t("pricing.starter.caption-yearly"),
    },
    {
      highlight: false,
      type: t("pricing.starter-plus.title"),
      title: t("pricing.starter-plus.yearly-price"),
      buttonText: t("pricing.starter-plus.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.starter-plus.item-4"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.starter-plus.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.starter-plus.item-6"),
          hasInfo: false,
        },
      ],
      billed: t("pricing.starter-plus.caption-yearly"),
    },
    {
      highlight: false,
      type: t("pricing.free.title"),
      title: t("pricing.free.price"),
      buttonText: t("pricing.free.button-text"),
      features: [
        {
          icon: IconSuccess,
          title: t("pricing.free.item-1"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-2"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-3"),
          hasInfo: false,
        },
        {
          icon: IconSuccess,
          title: t("pricing.free.item-4"),
          hasInfo: false,
        },
      ],
      notIncludes: [
        {
          icon: IconClose,
          title: t("pricing.free.item-5"),
          hasInfo: false,
        },
        {
          icon: IconClose,
          title: t("pricing.free.item-6"),
          hasInfo: false,
        },
      ],
    },
  ];
};
