import {
    FlagEngland, FlagFrance, FlagGermany, FlagGreek, FlagItaly, FlagRussia, FlagSaudi, FlagSpain
} from 'assets';

import { Language } from '@types';

const Languages : Language[] = [
  {
    name: 'English',
    code: 'en-en',
    flag: <FlagEngland className="h-8 w-auto mx-auto" />,
  },
  {
    name: 'Spanish',
    code: 'es-es',
    flag: <FlagSpain className="h-8 w-auto mx-auto" />
  },
  {
    name: 'French',
    code: 'fr-fr',
    flag: <FlagFrance className="h-8 w-auto mx-auto" />
  },
  {
    name: 'German',
    code: 'de-de',
    flag: <FlagGermany className="h-8 w-auto mx-auto" />,
    locked: true
  },
  {
    name: 'Italian',
    code: 'it-it',
    flag: <FlagItaly className="h-8 w-auto mx-auto" />
  },
  {
    name: 'Arab',
    code: 'ar-ae',
    flag: <FlagSaudi className="h-8 w-auto mx-auto" />,
    locked: true
  },
  {
    name: 'Russian',
    code: 'ru',
    flag: <FlagRussia className="h-8 w-auto mx-auto" />
  },
  {
    name: 'Greek',
    code: 'el-gr',
    flag: <FlagGreek className="h-8 w-auto mx-auto" />
  },
];

export default Languages;