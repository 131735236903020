import { EnfluLogo } from 'assets';
import { CustomTextField, theme as globalTheme } from 'config/theme';
import { gradients } from 'config/theme/theme';
import useAuth from 'hooks/useAuth.hook';
import { TFunction } from 'i18next';
import { NextButton } from 'pages/common/create-actions/CreateActions';
import Footer from 'pages/common/footer/Footer';
import { FC, forwardRef, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthService } from 'services';
import { EnfluContext } from 'store';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { alpha, Box, Button, Paper, Snackbar, Stack, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarConfig, UserLogin } from '@types';

/**
 * Form Validation Schema
 */
const schema: (t: TFunction) => yup.ObjectSchema<FormFields> = (t: TFunction) =>
  yup.object().shape({
  email: yup
    .string()
    .email(t("signup.invalid-email"))
    .required(t("signup.email-required")),
  password: yup.string().required(t("signup.password-required")),
});

interface FormFields {
  email: string;
  password: string;
}

const defaultValues: UserLogin = {
  email: "",
  password: "",
  remember: true,
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

const SignInPage: FC = () => {
  const { user } = useContext(EnfluContext);
  const auth = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues, email: user?.email },
    resolver: yupResolver(schema(t)),
  });
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
    severity: 'success',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isValid, errors } = formState;

  const onSubmit = async (data: FormFields) => {
    try {

      setIsSubmitting(true);
      const userSession = await AuthService.signin(data);
      setIsSubmitting(false);
      if (userSession) {
        setSnackbarConfig({
          open: true,
          message: t("signin.success"),
          severity: "success",
        });

        // remove local storage token
        localStorage.removeItem('token');
        auth.setSession(userSession);

        // Redirect to create
        navigate("/create/upload");
      } else {
        setSnackbarConfig({
          open: true,
          message: t("signin.error"),
          severity: "error",
        });
      }
    } catch (err) {
      setSnackbarConfig({
        open: true,
        message: t("signin.error"),
        severity: "error",
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{ background: gradients.home }}
      className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full"
    >
      <Box className="pt-20 md:pt-8">
        <Box className="pb-8 gap-y-8 flex flex-col">
          <Link to="/"><EnfluLogo className="w-32 h-32 mx-auto" /></Link>
          
        </Box>
      </Box>
      <Box className="bg-none p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto">
        <Typography
          variant="h2"
          className="font-inter text-white text-center mt-2 text-4xl md:text-3xl font-semibold tracking-tight leading-tight"
        >
          {t("signin.login-to-enflu")}
        </Typography>
      </Box>
      <Paper className="bg-none bg-enflu-grey-400 p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl">
        <form
          name="loginForm"
          noValidate
          className="flex flex-col justify-center w-full mb-4 sm:mb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Stack
                direction="column"
                spacing={2}
                className="flex mt-4 items-start"
              >
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.email")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
              </Stack>
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Stack
                direction="column"
                spacing={2}
                className="flex mt-4 items-start"
              >
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.password")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="password"
                  error={!!errors.password}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
              </Stack>
            )}
          />

          <Button
            variant="text"
            className="text-enflu-purple-100 mx-auto underline hover:bg-transparent normal-case my-4"
            component={Link}
            to="/forgot-password"
          >
            {t("signin.forgot-password")}
          </Button>

          <NextButton
            buttonStr="auth.login"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={isSubmitting}
            className="w-2/3 mx-auto"
          />
        </form>
      </Paper>

      <Box className="flex items-center my-4">
        <Box className="flex-auto mt-px" />
        <Typography className="mx-8 text-white">
          {t("signup.or")}
        </Typography>
        <Box className="flex-auto mt-px" />
      </Box>

      <Paper className="bg-none bg-enflu-grey-400 py-8 px-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl mb-16">
        <Stack
          direction="column"
          spacing={2}
          className="flex items-center justify-between"
        >
          <Button
            variant="contained"
            startIcon={<FacebookIcon />}
            className="text-enflu-grey-400 bg-white"
          >
            {t("signup.sign-up-with-facebook")}
          </Button>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            className="text-enflu-grey-400 bg-white"
          >
            {t("signup.sign-up-with-google")}
          </Button>
        </Stack>
      </Paper>
      <Snackbar
        open={snackbarConfig.open}
        autoHideDuration={3000}
        onClose={() => setSnackbarConfig({ ...snackbarConfig, open: false })}
      >
        <Alert severity={snackbarConfig.severity}>{snackbarConfig.message}</Alert>
      </Snackbar>
      <Footer className="w-screen mt-16"/>
    </Box>
  );
};

export default SignInPage;
