import { User } from '@types';

export enum ActionType {
  SET_USER = 'SET_USER',
  SET_DRAFT = 'SET_DRAFT',
}

const reducer = (state : any, action : any) => {
  switch (action.type) {
    case ActionType.SET_USER:
      return { ...state, user: action.payload as User };
    case ActionType.SET_DRAFT:
      return { ...state, draft: action.payload as File };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export default reducer;
