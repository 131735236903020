
import {
    AboutPage, CreatePage, DraftsPage, FinalizedPage, FinalizedProjectsPage, HomePage, LanguagePage,
    Page404, PreviewPage, PricingPage, PrivacyPolicy, ProjectsPage, PublicSharePage, SignInPage,
    SignUpPage, UploadPage, UploadVideoPage
} from 'pages';
import { RequireAuth } from 'pages/common';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import CreateAccount from '../pages/sign-up/create-account/CreateAccount';
import CreateEmailPassword from '../pages/sign-up/create-email-password/CreateEmailPassword';
import EmailConfirmation from '../pages/sign-up/email-confirmation/EmailConfirmation';

const router = () =>
  createBrowserRouter(
    createRoutesFromElements([
      <Route
        path="/"
        element={
          <HomePage />
        }
      />,
      <Route
        path="/about"
        element={
          <AboutPage />
        }
      />,
      <Route
        path="/pricing"
        element={
          <PricingPage />
        }
      />,
      <Route
        path="/create-account"
        element={
          <SignUpPage>
            <CreateAccount />
          </SignUpPage>
        }
      />,
      <Route
        path="/create-with-email"
        element={
          <SignUpPage>
            <CreateEmailPassword />
          </SignUpPage>
        }
      />,
      <Route
        path="/confirm-email-from-inbox"
        element={
          <SignUpPage>
            <EmailConfirmation />
          </SignUpPage>
        }
      />,
      <Route
        path='/signin'
        element={
          <SignInPage />
        }
        />,
      <Route
        path="/home"
        element={
          <UploadVideoPage />
        }
      />,
      <Route 
        path="/create/upload"
        element={
          <RequireAuth>
            <CreatePage tab={0} nav={0}>
              <UploadPage />
            </CreatePage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/create/language"
        element={
          <RequireAuth>
            <CreatePage tab={1} nav={0}>
              <LanguagePage />
            </CreatePage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/create/preview"
        element={
          <RequireAuth>
            <CreatePage tab={2} nav={0}>
              <PreviewPage />
            </CreatePage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/create/finalize"
        element={
          <RequireAuth>
            <CreatePage tab={3} nav={0}>
              <FinalizedPage />
            </CreatePage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/projects/drafts"
        element={
          <RequireAuth>
            <ProjectsPage nav={1} tab={0}>
              <DraftsPage />
            </ProjectsPage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/projects/finalized"
        element={
          <RequireAuth>
            <ProjectsPage nav={1} tab={1}>
              <FinalizedProjectsPage />
            </ProjectsPage>
          </RequireAuth>
        }
      />,
      <Route 
        path="/public-share/:id"
        element={<PublicSharePage />}
      />,
      <Route 
        path="/privacy-policy"
        element={<PrivacyPolicy />}
      />,
      <Route path="*" element={<Page404 />} />,
    ])
  );

export default router;
