import { configureStore } from '@reduxjs/toolkit';

import audioSlice from './reducers/audioSlice';
import configSlice from './reducers/configSlice';
import languageSlice from './reducers/languageSlice';
import projectsSlice from './reducers/projectsSlice';
import subscriptionSlice from './reducers/subscriptionSlice';
import userSlice from './reducers/userSlice';
import videoSlice from './reducers/videoSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    subscription: subscriptionSlice,
    video: videoSlice,
    projects: projectsSlice,
    config: configSlice,
    audio: audioSlice,
    language: languageSlice,
  },
});

export type EnfluState = ReturnType<typeof store.getState>;
export type EnfluDispatch = typeof store.dispatch;
export default store;
