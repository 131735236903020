import { IconArrowLeft, IconArrowRight, IconInstagram } from 'assets';
// Creators Pictures
import AbuJuliaPicture from 'assets/images/creators/Abu-Julia.jpg';
import AndrewHendersonPicture from 'assets/images/creators/Andrew-Henderson.jpg';
import EmadFarrajeenPicture from 'assets/images/creators/Emad-Farrajeen.png';
import TakhayaalPicture from 'assets/images/creators/Takhayaal.png';
import YianniPicture from 'assets/images/creators/Yianni.png';
import { gradients } from 'config/theme/theme';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import SwiperCore from 'swiper';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
    Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, Typography
} from '@mui/material';

SwiperCore.use([EffectCoverflow, Navigation]);

interface Creator {
  name: string;
  followers: string;
  picture: string;
  socials: {
    instagram: string;
  };
}

interface CreatorsProps {}



const creatorsList: Creator[] = [
  {
    name: 'Yianni',
    followers: '5M',
    picture: YianniPicture,
    socials: {
      instagram: '@yianni',
    },
  },
  {
    name: 'Takhayaal',
    followers: '2.5M',
    picture: TakhayaalPicture,
    socials: {
      instagram: '@takhayaal',
    },
  },
  {
    name: 'Emad Farrajeen',
    followers: '3.5M',
    picture:EmadFarrajeenPicture,
    socials: {
      instagram: '@emadfarrajeen',
    },
  },
  {
    name: "Abu Julia",
    followers: "20M",
    picture: AbuJuliaPicture,
    socials: {
      instagram: "@abu_julia",
    },
  },
  {
    name: 'Andrew Henderson',
    followers: '6M',
    picture: AndrewHendersonPicture,
    socials: {
      instagram: '@andrew_henderson',
    },
  }
]

const Creators: FC<CreatorsProps> = () => {
  const { t } = useTranslation();
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <>
      <Box className="pt-72 flex flex-col mx-auto text-center max-w-3xl justify-center gap-y-8">
        <Typography variant="h1" className="text-3xl font-bold text-white">
          {t("home.creators.title")}
        </Typography>
        <Typography variant="h2" className="text-xl font-medium text-white">
          {t("home.creators.subtitle")}
        </Typography>
      </Box>
      <Box className="w-11/12 md:w-full relative h-[400px] mt-16">
        <Swiper
          effect="slide"
          spaceBetween={30}
          centeredSlides={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          initialSlide={3}
          modules={[Navigation]}
          onSwiper={setSwiperRef}
          className="mySwiper h-[500px] w-full relative"
          loop
        >
          {creatorsList.map((creator, index) => (
            <SwiperSlide
              key={index}
              className="h-96 rounded-2xl overflow-hidden"
            >
              {({ isActive }) => (
                <Card
                  className={`h-96 w-full flex flex-col justify-end ${isHovered && isActive ? 'shadow-lg shadow-white/10 transform transition-all' : ''}`}
                  onMouseEnter={() => isActive && setIsHovered(true)}
                  onMouseLeave={() => isActive && setIsHovered(false)}
                >
                  <CardMedia
                    className="h-96 w-full absolute top-0 left-0 z-0"
                    image={creator.picture}
                    title={creator.name}
                  />
                  <CardContent className="relative">
                    <Typography
                      gutterBottom
                      variant="h5"
                      className="relative text-white z-10 font-medium font-inter"
                    >
                      {creator.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="relative text-enflu-blue-100 font-bold z-10 text-base font-inter"
                    >
                      {creator.followers} followers
                    </Typography>
                    <Box
                      className="absolute left-0 top-0 w-full h-full opacity-40 z-0"
                      sx={{ background: gradients.purples }}
                    />
                  </CardContent>
                  <CardActions className="flex flex-row text-white p-0 bg-enflu-purple-300 z-10"
                  >
                    <Button
                      size="small"
                      className={`flex flex-row gap-x-4 w-full py-2 rounded-none font-inter normal-case ${isHovered && isActive ? 'creators-item--btn-action-active' : 'bg-enflu-purple-300'}`}
                    >
                      <IconInstagram />
                      {creator.socials.instagram}
                    </Button>
                  </CardActions>
                </Card>
              )}
            </SwiperSlide>
          ))}
          <Box
            className="absolute h-full w-1/3 top-0 left-0 z-10"
            sx={{
              background: gradients.blackTransparencyLeft,
            }}
          />
          <Box
            className="absolute h-full w-1/3 top-0 right-0 z-10"
            sx={{
              background: gradients.blackTransparencyRight,
            }}
          />
          <Box className="absolute z-10 top-1/3 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-row justify-between w-11/12">
            <IconButton onClick={() => swiperRef?.slidePrev()}>
              <IconArrowLeft className="text-white" />
            </IconButton>
            <IconButton onClick={() => swiperRef?.slideNext()}>
              <IconArrowRight className="text-white" />
            </IconButton>
          </Box>
        </Swiper>
      </Box>
    </>
  );
};

export default Creators;
