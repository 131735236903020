import { IconEdit, IconUpgrade } from 'assets';
import { gradients, greyIsh } from 'config/theme/theme';
import { useSubscription } from 'hooks';
import { BackButton } from 'pages/common/create-actions/CreateActions';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material';
import { EnfluFile, Language } from '@types';

interface SummaryReviewProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenDraft: (open: boolean) => void;
  file: EnfluFile;
  language: Language;
}

export const SummaryReview : FC<SummaryReviewProps> = ({ open, setOpen, setOpenDraft, file, language }) => {
  const { t } = useTranslation();
  const subscription = useSubscription();

  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const renderSubtitles = () => {
    if (subscription?.active) {
      return (<Typography variant="body1" color="common.white" className="text-sm font-medium text-center">
        {t('summary-review.subscription-active')}
      </Typography>);
    } else {
      return (<Typography variant="body1" color="common.white" className="text-sm font-medium text-center">
        {t('summary-review.subscription-inactive')}
      </Typography>);
    }
  };

  const renderDescriptionCallToAction = () => {
    if (subscription?.active) {
      return (<Typography variant="body1" color="common.white" className="text-sm font-medium">
        {t('summary-review.action-description')}
      </Typography>);
    } else {
      return (<Typography variant="body1" color="common.white" className="text-sm font-medium">
        {t('summary-review.action-description-inactive')}
      </Typography>);
    }
  };

  const renderCallToActionButton = () => {
    if (subscription?.active) {
      return (
        <Button 
          variant="contained"
          className="mt-4 bg-cyan-500 text-white p-4 rounded-xl capitalize active font-bold text-lg text-white"
          sx={{ background: gradients.blues }}
          component={Link}
          to="/create/preview"
          >
            {t('summary-review.action-button')}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          className="mt-4 p-4 h-14 rounded-xl capitalize active font-bold text-base text-white"
          sx={{ background: gradients.purples }}
          startIcon={<IconUpgrade />}
          component={Link}
          to="/create/preview"
        >{t('general.upgrade-now')}</Button>);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="summary-review-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box className="fixed h-full w-full opacity-90" sx={{ backgroundColor: greyIsh[400] }} />
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/12 p-4">
          <Typography id="summary-review-title" variant="h6" component="h2" color="text.bluelights" className="text-center">
            {t('summary-review.title')}
          </Typography>
          {renderSubtitles()}
          <Box className="flex flex-row border border-fuchsia-500 rounded-xl p-8 my-4 w-full" >
            <Stack direction="column" spacing={2}>
              <Box className="flex flex-row items-center gap-x-4">
                <Typography variant="body1" color="common.white" className="text-sm font-medium">
                  {t('summary-review.file-uploaded')}
                </Typography>
                <Box className="text-sm p-4 bg-gray-700 text-cyan-400 rounded-xl">
                  {file.name} ({file.size})
                </Box>
                <Tooltip title={t('summary-review.edit-file')}>
                  <IconButton aria-label="edit" color="primary" onClick={() => navigate("/create/upload")}>
                    <IconEdit />
                  </IconButton>
                </Tooltip>
              </Box>
              {language?.name && (<Box className="flex flex-row items-center gap-x-4">
                <Typography  variant="body1" color="common.white" className="text-sm font-medium">
                  {t('summary-review.translate-to')}
                </Typography>
                <Box className="flex flex-row items-center gap-x-2 text-sm p-4 bg-gray-700 text-cyan-400 rounded-xl">
                  {language.flag}
                  {language.name}
                </Box>
                <Tooltip title={t('summary-review.edit-language')}>
                  <IconButton aria-label="edit" color="primary" onClick={handleClose}>
                    <IconEdit />
                  </IconButton>
                </Tooltip>
              </Box>)}
            </Stack>
          </Box>
          <Box className="flex flex-col items-center">
            {renderDescriptionCallToAction()}
            {renderCallToActionButton()}
            <Box className="mt-8 flex flex-row gap-x-4 justify-center items-center mx-auto">
              <BackButton url='/create/upload' variant='outlined' hasArrow />
              <Button variant="outlined" className="border-white rounded-xl text-white py-2 capitalize" onClick={() => setOpenDraft(true)}>{t('general.save-to-draft')}</Button>
            </Box>
          </Box>
        </Box>
      </>
    </Modal>
  )
};