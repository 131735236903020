import { IconCreate, IconLangFilter, IconProjects, IconUpgrade } from 'assets';
import { MenuPurpleGradientListButton, WhitePurpleButton } from 'config/theme';
import { gradients, greyIsh } from 'config/theme/theme';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscChevronLeft } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { toggleMenu as setToggleMenu } from 'store/reducers/configSlice';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
    alpha, Avatar, Box, Button, CssBaseline, IconButton, ListItem, ListItemIcon, ListItemText,
    Paper, Stack, Typography
} from '@mui/material';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

interface MenuProps {
  nav: number;
  open: boolean;
  setOpen?: (open: boolean) => void;
}

const drawerWidth = 256;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    "& .MuiDrawer-paper": {
      display: "flex",
      justifyContent: "space-between",
      overflow: "initial",
      margin: "20px",
      border: "none",
      borderRadius: 20,
      width: drawerWidth,
      height: "95%",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRight: "none",
      background: gradients.menu,
    },
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      display: "flex",
      margin: "20px",
      justifyContent: "space-between",
      overflow: "initial",
      width: "119px",
      height: "95%",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      borderRight: "none",
      borderRadius: 20,
      background: gradients.menu,
    },
  }),
}));

const menuItemsLogged = (t: any) => [
  {
    icon: <IconCreate />,
    text: t("menu.create"),
    dest: "/create/upload",
  },
  {
    icon: <IconProjects />,
    text: t("menu.projects"),
    dest: "/projects/drafts",
  }
];

const Menu: FC<MenuProps> = ({
  nav,
  open: propsOpen = false,
  setOpen: propsSetOpen = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useEnfluDispatch();
  // get the language from the i18n object
  const { user, subscription } = useEnfluSelector((state) => state);
  const [isRotated, setIsRotated] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(propsOpen);

  const closeMenu = () => {
    setOpen(false);
    propsSetOpen && propsSetOpen(false);
  };
  const toggleMenu = () => {
    setIsRotated(!isRotated);
    setOpen(!open);
    propsSetOpen && propsSetOpen(!open);
    dispatch(setToggleMenu(!open));
  };

  const handleLanguageChange = (lang: string) => {
    if (lang === i18n.language) return;
    i18n.changeLanguage(lang);
  };

  const isMobile = window.innerWidth < 768;

  return (
    <Box className="flex">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        {isMobile && (
          <DrawerHeader>
            <IconButton onClick={closeMenu}>
              <VscChevronLeft />
            </IconButton>
          </DrawerHeader>
        )}
        <Box>
          <Box
            className={`relative flex justify-start items-center mt-8 mb-4 px-5 ${!open && "flex-col"}`}
          >
            <Avatar src={user.avatar} alt={user.first_name} />
            <Box className="flex flex-col ml-4">
              <Typography variant="h6" className="text-white">
                {user.first_name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {subscription.status}
              </Typography>
            </Box>
            <IconButton
              aria-label="close-menu"
              className="absolute -right-5 rounded-full bg-gradient-to-r from-purple-500 to-pink-500"
              onClick={toggleMenu}
              sx={{
                transition: "transform .4s",
                transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          </Box>
          {menuItemsLogged(t).map((itemMenu, index) => (
            <ListItem key={index} className="py-1">
              <Link to={itemMenu.dest} className="w-full">
                <MenuPurpleGradientListButton
                  className={`w-full hover:w-[208px] menu-item-button transition-all ease-in-out duration-300 ${nav === index ? "active" : ""}`}
                >
                  <ListItemIcon
                    className="justify-center"
                    sx={{
                      mr: open ? 3 : "auto",
                      fontSize: "1.4em",
                      color: alpha(greyIsh[100], 0.5),
                      ...(nav === index && {
                        color: "common.white",
                      }),
                    }}
                  >
                    {itemMenu.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={itemMenu.text}
                    sx={{ opacity: open ? 1 : 0 }}
                    className="menu-item-button-text transition-all ease-in-out duration-300"
                  />
                </MenuPurpleGradientListButton>
              </Link>
            </ListItem>
          ))}
        </Box>
        <Stack direction="column" className="mx-4 mb-2">
          <Paper
            sx={{ backgroundColor: alpha(greyIsh[100], 0.05) }}
            className="flex flex-col gap-y-4 justify-center items-center rounded-3xl p-6"
          >
            {subscription.status === "active" ? (
              <>
                <Typography variant="h6" className="text-white">
                  {subscription.timeLeft}
                </Typography>
                <Typography variant="caption" className="text-white">
                  {t("menu.mins")}
                </Typography>
              </>
            ) : (
              <>
                <IconUpgrade className="h-12 w-auto" />
                {
                  <>
                    <Typography
                      color="common.white"
                      variant="caption"
                      className="text-wrap text-center"
                    >
                      {t("menu.description-upgrade-now")}
                    </Typography>
                    <WhitePurpleButton color="primary" variant="contained">
                      {t("menu.upgrade-now")}
                    </WhitePurpleButton>
                  </>
                }
              </>
            )}
          </Paper>
          <Button
            sx={{ backgroundColor: alpha(greyIsh[400], 0.3) }}
            variant="contained"
            color="primary"
            endIcon={<IconLangFilter />}
            className={` mx-auto ${open ? "w-full rounded-2xl" : "rounded-full"} flex justify-between mt-4`}
            onClick={() =>
              handleLanguageChange(i18n.language === "en" ? "ar" : "en")
            }
          >
            {open && (
              <Box className="flex flex-col justify-left items-start">
                <Typography
                  color="secondary.light"
                  variant="body2"
                  className="font-normal normal-case"
                >
                  {t("menu.language")}
                </Typography>
                <Typography variant="caption" className="text-white">
                  {t("menu.language-english")}
                </Typography>
              </Box>
            )}
          </Button>

          {/*
            <Button sx={{ backgroundColor: alpha(greyIsh[400], .3) }} variant="contained" color="primary" endIcon={<AddIcon />} className={`mt-4 mx-auto ${menu ? 'w-full rounded-2xl' : 'rounded-full'} flex justify-between`}>
              {menu && <Box className="flex flex-col items-start">
                <Typography color="secondary.light" variant="body2" className="font-normal normal-case">{t('menu.current-mode')}</Typography>
                <Typography variant="caption" className="text-white">{t('menu.dark-mode')}</Typography>
              </Box>
              }
            </Button>
            <Button 
              sx={{ backgroundColor: alpha(greyIsh[400], .3) }}
              variant="contained" 
              color="primary" 
              endIcon={<IconLangFilter />} 
              className={` mx-auto ${open ? 'w-full rounded-2xl' : 'rounded-full'} flex justify-between mt-4`}
              onClick={() => handleLanguageChange(i18n.language === 'en' ? 'ar' : 'en')}
              >
              {open && <Box className="flex flex-col justify-left items-start">
                <Typography color="secondary.light" variant="body2" className="font-normal normal-case">{t('menu.language')}</Typography>
                <Typography variant="caption" className="text-white">{t('menu.language-english')}</Typography>
              </Box>}
            </Button>
            */}
        </Stack>
      </Drawer>
    </Box>
  );
};

export default Menu;
