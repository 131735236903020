import { blues, gradients, purples } from 'config/theme/theme';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';

type Props = {
  tab?: number;
}

export const menuItems = (t: any) => [
  {
    label: t('create.upload'),
    path: '/create/upload',
  },
  {
    label: t('create.language'),
    path: '/create/language',
  },
  {
    label: t('create.preview-video'),
    path: '/create/preview',
  },
  {
    label: t('create.finalized'),
    path: '/create/finalize',
  }
];

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateTabs : React.FC<Props> = ({ tab } : Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const getDefaultTabFromPath = () : number | null => {
    const path = location.pathname;
    const index = menuItems(t).findIndex((item) => item.path === path);
    return index;
  };

  const getDefaultVisitedTabsFromPath = () : number[] => {
    const path = location.pathname;
    const index = menuItems(t).findIndex((item) => item.path === path);
    return index > 0 ? Array.from({ length: index }, (_, i) => i) : [];
  };

  const [selectedTab, setSelectedTab] = useState<number>(tab || getDefaultTabFromPath() || 0);
  const [visitedTabs, setVisitedTabs] = useState<number[]>(getDefaultVisitedTabsFromPath() || []);

  const handleChange = (event: React.SyntheticEvent, newSelectedTab: number) => {
    setVisitedTabs((prev) => [...prev, selectedTab]);
    setSelectedTab(newSelectedTab);
  };

  useEffect(() => {
    setVisitedTabs((prev) => {
      // todo: improve this
      if (tab === 0) return [];
      else if (tab === 1) return [0];
      else if (tab === 2) return [0, 1];
      else if (tab === 3) return [0, 1, 2];
      else return prev;
    });
    setSelectedTab(tab || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      aria-label="basic tabs"
      sx={{
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
        gap: '20px'
      },
      '& .MuiTabs-indicator': {
        background: gradients.full
      },
    }}>
      {
        menuItems(t).map((item, index) => {
          return (
            <Tab 
              className={`normal-case min-w-28 ${visitedTabs.includes(index) ? 'visited-tab' : ''}`} 
              sx={{
                '&, &.Mui-disabled': {
                  color: 'secondary.main',
                  borderBottom: `4px dotted ${blues[300]}`
                },
                '&.Mui-selected': {
                  color: 'primary.light',
                  border: 'none',
                },
                '&.visited-tab': {
                  color: 'primary.main',
                  borderBottom: `2px solid ${purples[200]}`
                }
              }}
              label={item.label} 
              component={Link} 
              to={item.path}
              {...a11yProps(index)}
              disabled={visitedTabs.includes(index) ? false : true}
            />
          )
        })
      }
    </Tabs>
  )
};

export default CreateTabs;