import { EnfluLogo, IconDelete, IconDuplicate, IconLangFilter, IconShare } from 'assets';
import { blues } from 'config/theme/theme';
import { FC } from 'react';

import {
    Box, Card, CardActions, CardContent, CardMedia, Chip, IconButton, Stack, Typography
} from '@mui/material';
import { Project } from '@types';

interface ProjectCardProps {
  project: Project;
}

const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  return (
    <Card sx={{ background: blues[500] }} className="rounded-2xl max-w-72">
      {project.thumbnail ? (
        <CardMedia
          sx={{ height: 140 }}
          className="m-4 rounded-2xl"
          image={project.thumbnail}
          title="green iguana"
        />
      ) : (
        <Box className="flex justify-center mt-4 rounded-2xl overflow-hidden">
          <EnfluLogo width={40} height={40} />
        </Box>
      )}
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          className="text-white font-semibold"
        >
          {project.title}
        </Typography>
        <Typography variant="body2" className="text-enflu-grey-200 font-medium">
          <span className="block">
            {new Date(project.createdAt).toDateString()}
          </span>
          <span>{new Date(project.updatedAt).toDateString()}</span>
        </Typography>
      </CardContent>
      <CardActions className="flex flex-row justify-between">
        <Chip
          label={
            <Typography
              variant="body2"
              className="text-white font-medium flex flex-row items-center gap-x-2"
            >
              <IconLangFilter className="text-white w-4 h-4" />
              {project.language}
            </Typography>
          }
          className="m-2 bg-enflu-grey-400 text-white"
        />
        <Stack className="flex flex-row">
          <IconButton>
            <IconDuplicate className="text-white" />
          </IconButton>
          <IconButton>
            <IconShare className="text-white" />
          </IconButton>
          <IconButton>
            <IconDelete className="text-white" />
          </IconButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
