import { gradients } from 'config/theme/theme';
import React, { forwardRef, useState } from 'react';

import { Box } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ChildProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

interface Props {
  children: React.ReactElement<ChildProps>;
}

const SignUpPage: React.FC<Props> = ({ children } : Props) => {
  const [email, setEmail] = useState('');
  return (
    <Box sx={{ background: gradients.home }} className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full">
      {React.Children.map(children, (child: React.ReactElement<{ email: string; setEmail: React.Dispatch<React.SetStateAction<string>>; }>) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { email, setEmail });
        }
        return child;
      })}
    </Box>
  );
}

export default SignUpPage;
