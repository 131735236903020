import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Subscription } from "@types";

import type { EnfluState } from "../store";

const initialState: Subscription = {
  name: "Free",
  price: 0,
  description: "Free subscription",
  timeLeft: 23,
  status: "active",
  active: true,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      return action.payload;
    },
    clearSubscription: () => initialState,
  },
});

export const { setSubscription, clearSubscription } = subscriptionSlice.actions;
export const selectSubscription = (state: EnfluState) => state.subscription;
export default subscriptionSlice.reducer;
