import { FC } from 'react';

import { Card, SxProps, Theme } from '@mui/material';
import { Video } from '@types';

import CardVideoContent from './CardVideoContent';

interface CardVideoProps {
  video: Video;
  controls: boolean;
  className?: string;
  isCentered?: boolean;
  sx?: SxProps<Theme>;
  preload?: "auto" | "metadata" | "none";
}

const CardVideo: FC<CardVideoProps> = ({
  video,
  controls,
  isCentered = true,
  className = "",
  sx = {},
  preload = "none",
}) => {
  return (
    <Card
      className={`shadow-md border-[16px] rounded-2xl ${isCentered ? "absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2" : ""} ${className}`}
      sx={sx}
    >
      <CardVideoContent video={video} preload={preload} controls={controls} />
    </Card>
  );
};

export default CardVideo;
