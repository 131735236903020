import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project } from "@types";

import type { EnfluState } from "../store";

const initialState: Project[] = [];

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Project[]>) => {
      return action.payload;
    },
    addProject: (state, action: PayloadAction<Project>) => {
      state.push(action.payload);
    },
    updateProject: (state, action: PayloadAction<Project>) => {
      const index = state.findIndex(
        (project) => project.id === action.payload.id
      );
      state[index] = action.payload;
    },
    deleteProject: (state, action: PayloadAction<string>) => {
      return state.filter((project) => project.id !== action.payload);
    },
  },
});

export const { setProjects, addProject, updateProject, deleteProject } =
  projectsSlice.actions;
export const selectProjects = (state: EnfluState) => state.projects;
export default projectsSlice.reducer;
