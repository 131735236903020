// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

import { EnfluLogo } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { useAuth } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useEnfluSelector } from 'store/hooks';
import { selectUser } from 'store/reducers/userSlice';

import { Avatar, Box, Button, Typography, useMediaQuery } from '@mui/material';

interface NavBarItemsTypes {
  name: string;
  link: string;
}
const NavBarItems = [
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Pricing",
    link: "/pricing",
  },
  {
    name: "Contact",
    link: "mailto:hello@enflu.ai",
  },
];

interface HomeHeaderProps {
  navBarSelectionIndex?: number;
}

const HomeHeader: FC<HomeHeaderProps> = ({ navBarSelectionIndex }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const { isLogged } = useAuth();
  const user = useEnfluSelector(selectUser);

  const [onScroll, setOnScroll] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOnScroll(true);
      } else {
        setOnScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      className={`md:mt-4 flex md:fixed z-20 inset-0 justify-center items-center bg-enflu-grey-400 md:left-1/2 transform md:-translate-x-1/2 shadow-lg transition-all ${isMobile ? "h-[70px] rounded-3xl mx-auto w-[95%]" : ""} ${onScroll && !isMobile ? "h-[70px] w-[95%] rounded-xl" : !isMobile ? "h-[110px] w-11/12 rounded-[2.5rem]" : ""}`}
    >
      <Box className="w-full mx-auto flex md:justify-between px-8">
        <Link to="/" className="hidden md:flex">
          <EnfluLogo className={onScroll ? "w-16" : "w-24"} />
        </Link>
        <Box className="w-full md:w-auto flex mx-auto flex-row md:justify-center items-center md:gap-x-16">
          {NavBarItems.map((item: NavBarItemsTypes, index: number) => (
            <Button
              key={index}
              variant={navBarSelectionIndex === index ? "outlined" : "text"}
              component={Link}
              to={item.link}
              className={` font-inter font-semibold hover:underline normal-case ${navBarSelectionIndex === index ? "text-enflu-blue-100" : "text-white"}`}
            >
              {t(`home.header.${item.name.toLowerCase()}`)}
            </Button>
          ))}
        </Box>
        <Box className="items-center gap-x-4 hidden md:flex">
          {isLogged && user ? (
            <Link to={"/create/upload"} className="flex flex-row gap-x-4">
              <Avatar />
              <Box className="flex flex-col">
                <Typography className="text-white">
                  {user.first_name} {user.last_name}
                </Typography>
                {user.role ? (
                  <Typography className="text-white">{user.role}</Typography>
                ) : null}
              </Box>
            </Link>
          ) : (
            <>
              <Button
                component={Link}
                to="/signin"
                variant="outlined"
                className="border-enflu-purple-100 rounded-lg text-white normal-case font-semibold"
              >
                {t("home.header.login")}
              </Button>
              <WhitePurpleButton
                onClick={() => navigate("/create-account")}
                className="normal-case rounded-lg font-semibold"
              >
                {t("home.header.signup")}
              </WhitePurpleButton>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HomeHeader;
