import { IconArrowDown, IconLangFilter } from 'assets';
import { ReactComponent as EnfluLogo } from 'assets/logos/enflu_dark_logo.svg';
import { CustomTab, CustomTabs, CustomTextField, theme as globalTheme } from 'config/theme';
import { Menu } from 'pages/common';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { setProjects } from 'store/reducers/projectsSlice';
import * as yup from 'yup';

import { Project } from '@/@types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box, Button, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Stack
} from '@mui/material';

interface ChildProps { 
  projects: Project[];
  duplicateProject: (project: Project) => void;
  deleteProject: (project: Project) => void;
};

type ChildElement = React.ReactElement<ChildProps>;

interface ProjectsPageProps {
  children: ChildElement | ChildElement[];
  nav: number;
  tab: number;
};

const schema = (t : any) => yup.object().shape({
  search: yup.string(),
});

const defaultValues = {
  search : ""
};


const ProjectsPage: FC<ProjectsPageProps> = ({ children, nav, tab }) => {
  const { t } = useTranslation();
  const globalProjects = useEnfluSelector((state) => state.projects);
  const tMenu = useEnfluSelector((state) => state.config.menu);
  const dispatch = useEnfluDispatch();
  const navigate = useNavigate();

  const [localProjects, setLocalProjects] = useState<Project[]>([]);
  const [openMenu, setOpenMenu] = useState<boolean>(tMenu.open);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (tab === 0) {
      setLocalProjects(globalProjects.filter((project) => project.status === 'draft'));
    } else if (tab === 1) {
      setLocalProjects(globalProjects.filter((project) => project.status === 'finalized'));
    }
  }, [tab, globalProjects]);

  const { control, formState, handleSubmit, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  const { isValid, errors } = formState;
  const openAnchorEl = Boolean(anchorEl);
  const anchorId = openAnchorEl ? 'sort-button' : undefined;

  // Filter projects based on search query
  useEffect(() => {
    const search = watch('search');
    if (search) {
      setLocalProjects(
        globalProjects.filter((project) => project.title.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setLocalProjects(globalProjects);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('search')]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate('/projects/drafts');
    } else if (newValue === 1) {
      navigate('/projects/finalized');
    }
  };

  const handleDuplicateProject = (project: Project) => {
    const randomNumericId = Math.floor(Math.random() * 1000).toString();
    const newProject = { ...project, id: randomNumericId, title: `${project.title} - Copy` };
    setLocalProjects([...localProjects, newProject]);
  };

  const handleDeleteProject = (project: Project) => {
    setLocalProjects(localProjects.filter((p) => p.id !== project.id));
  };

  useEffect(() => {
    if (localProjects.length > globalProjects.length) {
      dispatch(setProjects(localProjects));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localProjects]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ bgcolor: 'background.default' }} className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full">
      <Menu nav={nav} open={openMenu} setOpen={setOpenMenu} />
      <Box className={`w-[808px] transition-all ease-in-out ${openMenu ? 'ml-[300px]' : 'ml-40'}`}>
        <Box className="mx-auto flex">
          <EnfluLogo className="mx-auto" />
        </Box>
        <Box className="relative">
          <CustomTabs value={value} onChange={handleChange} className="flex justify-center mx-auto">
            <CustomTab label={t('projects.drafts')} className="px-4 py-2 mx-2" />
            <CustomTab label={t('projects.finalized')} className="px-4 py-2 mx-2" />
          </CustomTabs>
        </Box>
        <Box className="mt-8">
          <form
            name="uploadVideoForm"
            noValidate
            className="flex flex-col justify-center w-full my-4 sm:mb-0"
            onSubmit={() => {}}
          >
            <Stack spacing={2} direction="row" className="justify-center w-full my-4 sm:mb-0">
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    className="text-slate-300 font-light grow w-full rounded-lg"
                    placeholder={t('create.video-name')}
                    autoFocus
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
              <Box>
                <Button 
                  variant="outlined"
                  aria-describedby={anchorId}
                  endIcon={<IconArrowDown />}
                  className="border-white text-white font-bold text-sm"
                  onClick={handleClick}
                >
                  {t('general.sort-by')}
                </Button>
                <Popover
                  id={anchorId}
                  open={openAnchorEl}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  
                >
                  <List className="p-0 border-white text-white" sx={{ backgroundColor: globalTheme.greyIsh[400] }}>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary="ASC" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText primary="DESC" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Button
                variant='outlined'
                className="border-white text-white font-bold text-sm w-full"
                startIcon={<IconLangFilter />}
                onClick={() => {}}
              >
                {t('general.search')}
              </Button>
            </Stack>
          </form>
        </Box>
        {
          React.Children.map(children, (child: ChildElement) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { 
                projects: localProjects,
                duplicateProject: handleDuplicateProject,
                deleteProject: handleDeleteProject
              });
            }
            return child;
          })
        }
      </Box>
    </Box>
  )
};

export default ProjectsPage;
