import { CustomTextField } from 'config/theme';
import { greyIsh } from 'config/theme/theme';
import { BackButton } from 'pages/common/create-actions/CreateActions';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';

import { Box, Button, Card, CardContent, Modal, Typography } from '@mui/material';
import { Audio, Video } from '@types';

interface SaveAsDraftProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  video?: Video;
  audio?: Audio;
}

export const SaveAsDraft : FC<SaveAsDraftProps> = ({ open, setOpen, video, audio }) => {
  const { t } = useTranslation();
  const dispatch = useEnfluDispatch();
  const projects = useEnfluSelector(state => state.projects);
  
  const handleClose = () => setOpen(false);

  const handleSaveAsDraft = () => {
    /*
    if (video && !projects.find(project => project.videos.includes(video))) {
      
      dispatch(addProject({
        id: '1',
        title: 'Project 1',
        description: 'Project 1 description',
        status: 'draft',
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date(),
        tags: [],
        video,
        audios: [],
      }));
    }
    if (audio && !projects.find(project => project.audios.includes(audio))) {
      dispatch(addProject({
        id: '2',
        title: 'Project 2',
        description: 'Project 2 description',
        status: 'draft',
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date(),
        tags: [],
        video: null,
        audio,

      }));
    
    }
    setOpen(false);
      */
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="save-as-draft-modal-title"
      aria-describedby="save-as-draft-modal-description"
    >
      <>
        <Box className="fixed h-full w-full opacity-90" sx={{ backgroundColor: greyIsh[400] }} />
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/12 p-4">
          <Typography variant="h6" component="h2" color="text.bluelights" className="text-center font-medium">
            {t('save-as-draft.title')}
          </Typography>
          <Typography variant="body1" component="p" className="text-center text-white">
            {t('save-as-draft.description')}
          </Typography>
          <Card className="flex mt-4 mb-8 rounded-2xl p-0 bg-transparent border border-white lg:min-h-52 justify-center items-center">
            <CardContent className="p-4 w-11/12">
              <Typography variant="body1" component="p" className="text-center text-white font-medium">
                {t('save-as-draft.video-title')}
              </Typography>
              <form>
                <CustomTextField
                  variant="outlined"
                  className="w-full mt-2 text-white rounded-xl"
                  placeholder={t('save-as-draft.video-title-placeholder')}
                />
              </form>
            </CardContent>
          </Card>
          <Typography variant="body2" component="p" className="text-center text-white">
            {t('save-as-draft.you-may-save')} 
            <Typography variant="body2" component="span" className="text-white font-medium">
              {' '}
              2
              {' '}
              {t('save-as-draft.more-drafts')}
            </Typography>
            <Button variant="text" className="underline capitalize" onClick={() => {}}>{t('general.upgrade')}</Button>
            <Typography variant="body2" component="span" className="text-white">
              {t('save-as-draft.to-be-able-to-save-more')}
            </Typography>
          </Typography>
          <Box className="mt-8 flex flex-row gap-x-4 justify-center items-center mx-auto">
            <BackButton url='/create/upload' variant='outlined' hasArrow />
            <Button variant="outlined" className="border-white rounded-xl text-white py-2 capitalize" onClick={() => handleSaveAsDraft()}>{t('general.save-to-draft')}</Button>
          </Box>
        </Box>
      </>
    </Modal>
  )
};