import { IconInfo, IconTiktok, IconXtwitter } from 'assets';
import { CustomTextField } from 'config/theme';
import { greyIsh } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Card, CardContent, IconButton, Modal, Stack, Typography } from '@mui/material';
import { Video } from '@types';

interface SummaryReviewProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  video: Video;
}

export const ShareVideo : FC<SummaryReviewProps> = ({ open, setOpen, video }) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(video?.url || '');
      alert('Link copied to clipboard');
    } catch (err) {
      console.error('Failed to copy link: ', err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="summary-review-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box className="fixed h-full w-full opacity-90" sx={{ backgroundColor: greyIsh[400] }} />
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-5/12 p-4">
          <Typography id="summary-review-title" variant="h6" component="h2" color="text.bluelights" className="text-center">
            {t('share-video.description')}
          </Typography>
          <Card className="mt-4 mb-8 rounded-2xl p-0">
            <CardContent className="p-0">
              <video
                autoPlay
                loop
                muted
                poster="https://assets.codepen.io/6093409/river.jpg"
              >
                <source
                  src="https://assets.codepen.io/6093409/river.mp4"
                  type="video/mp4"
                />
              </video>
            </CardContent>
          </Card>
          <Box className="flex items-center space-x-2">
            <CustomTextField
              value={video?.url}
              variant="outlined"
              className="flex-grow w-11/12"
            />
            <IconButton onClick={handleCopy} className="w-1/12 w-10 h-10">
              <IconInfo />
            </IconButton>
          </Box>
          <Stack direction="row" spacing={2} className="justify-center mt-4">
            <Link to="#">
              <IconButton className="bg-purple-700">
                <InstagramIcon />
              </IconButton>
            </Link>
            <Link to="#">
              <IconButton className="bg-purple-700">
                <FacebookIcon />
              </IconButton>
            </Link>
            <Link to="#">
              <IconButton className="bg-purple-700">
                <IconTiktok />
              </IconButton>
            </Link>
            <Link to="#">
              <IconButton className="bg-purple-700">
                <IconXtwitter />
              </IconButton>
            </Link>
          </Stack>
        </Box>
      </>
    </Modal>
  )
};