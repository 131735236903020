import { EnfluLogo } from 'assets';
import { CustomTextField, theme as globalTheme } from 'config/theme';
import { TFunction } from 'i18next';
import { NextButton } from 'pages/common/create-actions/CreateActions';
import Footer from 'pages/common/footer/Footer';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthService } from 'services';
import { useEnfluDispatch } from 'store/hooks';
import { setUser } from 'store/reducers/userSlice';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { alpha, Box, Button, Paper, Stack, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { User } from '@types';

type FormFields = {
  email: string;
};

interface Props {
  email?: string;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Form Validation Schema
 */
const schema : (t: TFunction) => yup.ObjectSchema<FormFields> = (t : TFunction) => yup.object().shape({
  email: yup.string().email(t('auth.yup-email-validation')).required(t('auth.yup-email-required')),
});

const defaultValues : FormFields = {
  email: '',
};


const CreateAccount : React.FC<Props> = (props : Props) => {
  const dispatch = useEnfluDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema(t)),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isValid, errors } = formState;

  const onSubmit = async (data : FormFields) => {
    setIsSubmitting(true);
    const { email } = data;
    if (props?.setEmail) props.setEmail(email);
    try {
      const status = await AuthService.checkUserByEmail(data.email);
      if (status !== 200) {
        navigate('/create-with-email', { replace: true });
        return;
      }
      const user : User = {
        email
      };
      dispatch(setUser(user));
      setIsSubmitting(false);
      navigate('/signin', { replace: true });
    } catch (err) {
      setIsSubmitting(false);
      navigate('/create-with-email', { replace: true })
    }
    
  };

  return (
    <Box
      className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full"
    >
      <Box className="pt-20 md:pt-8">
        <Box className="pb-8 gap-y-8 flex flex-col">
          <Link to="/">
            <EnfluLogo className="w-32 h-32 mx-auto" />
          </Link>
        </Box>
      </Box>
      <Box className="bg-none p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto">
        <Typography
          variant="h2"
          className="font-inter text-white text-center mt-2 text-4xl md:text-3xl font-semibold tracking-tight leading-tight"
        >
          {t('auth.sign-up')}
        </Typography>
      </Box>
      <Paper className="bg-none bg-enflu-grey-400 p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl">
        <form
          name="loginForm"
          noValidate
          className="flex flex-col justify-center w-full gap-y-4 mb-4 sm:mb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Stack
                direction="column"
                spacing={2}
                className="flex mt-4 items-center"
              >
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.email")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
              </Stack>
            )}
          />

          <NextButton
            buttonStr={t("signup.next")}
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={isSubmitting}
          />
        </form>
      </Paper>

      <Box className="flex items-center my-4">
        <Box className="flex-auto mt-px" />
        <Typography className="mx-8 text-white">
          {t("signup.or")}
        </Typography>
        <Box className="flex-auto mt-px" />
      </Box>

      <Paper className="bg-none bg-enflu-grey-400 py-8 px-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl mb-16">
        <Stack
          direction="column"
          spacing={2}
          className="flex items-center justify-between"
        >
          <Button
            variant="contained"
            startIcon={<FacebookIcon />}
            className="text-enflu-grey-400 bg-white"
          >
            {t("signup.sign-up-with-facebook")}
          </Button>
          <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            className="text-enflu-grey-400 bg-white"
          >
            {t("signup.sign-up-with-google")}
          </Button>
        </Stack>
      </Paper>
      <Footer className="w-screen" />
    </Box>
  );
}

export default CreateAccount;
