import axios from "axios";

import { Subscription } from "@types";

const API_URL = process.env.REACT_APP_API_URL;

const SubscriptionService = {
  getSubscriptions: async (): Promise<Array<Subscription>> => {
    try {
      const response = await axios.get(`${API_URL}/subscription`);
      return response.data;
    } catch (error) {
      console.error("Error getting user with email", error);
      return [];
    }
  },
  getCurrentSubscription: async (): Promise<Subscription> => {
    try {
      const response = await axios.get(`${API_URL}/subscription/current`);
      return response.data;
    } catch (error) {
      console.error("Error getting user with email", error);
      return {};
    }
  },
  getSubscriptionById: async (id: string): Promise<Subscription> => {
    try {
      const response = await axios.get(`${API_URL}/subscription/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error getting user with email", error);
      return {};
    }
  },
  createSubscription: async (
    subscription: Subscription
  ): Promise<Subscription> => {
    try {
      const response = await axios.post(
        `${API_URL}/subscription`,
        subscription
      );
      return response.data;
    } catch (error) {
      console.error("Error getting user with email", error);
      return {};
    }
  },
  updateSubscription: async (
    subscription: Subscription
  ): Promise<Subscription> => {
    try {
      const response = await axios.put(
        `${API_URL}/subscription/${subscription.id}`,
        subscription
      );
      return response.data;
    } catch (error) {
      console.error("Error getting user with email", error);
      return {};
    }
  },
};

export default SubscriptionService;
