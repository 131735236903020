import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "@types";

import type { EnfluState } from "../store";

const initialState: User = {
  id: 0,
  email: "me@enflu.ai",
  first_name: "Enflu",
  last_name: "Enflu",
  role: "free",
  guest: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
    clearUser: () => initialState,
  },
});

export const { setUser, clearUser } = userSlice.actions;
export const selectUser = (state: EnfluState) => state.user;
export default userSlice.reducer;
