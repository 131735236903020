import { EnfluLogo, IconShare } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { blues } from 'config/theme/theme';
import useAuth from 'hooks/useAuth.hook';
import { LinearProgressWithLabel } from 'pages/common/uploader/Uploader';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { VideoService } from 'services';
import { useEnfluSelector } from 'store/hooks';

import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { Video } from '@types';

const PublicSharePage : FC = () => {
  const { t } = useTranslation();
  const { token } = useAuth();

  const testVideo = useEnfluSelector(state => state.video);

  // get the video id from the url /public-share/:videoId
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [video, setVideo] = useState<Video | null>(testVideo || null);

  useEffect(() => {
    // fetch the video from the server using the id
    if (id && token) {
      // fetch the video
      const getVideo = async () => {
        const video: Video | null = await VideoService.getVideoById(id, token);
        if (video) {
          setVideo(video);
        }
        setIsLoading(false);
      };

      getVideo();

    }
  }, [id, token]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevStatus) => prevStatus >= 100 ? 10 : prevStatus + 10);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ bgcolor: 'background.default' }} className={`flex flex-col flex-auto items-center sm:justify-center min-w-0 ${video ? 'h-fit' : 'h-screen'}`}>
      <Box className={`transition-all ease-in-out`}>
        <Box className="mx-auto flex">
          <Link to="/" className="mx-auto">
            <EnfluLogo className="mx-auto" />
          </Link>
        </Box>
        <Box className="mt-4">
          <Card className="mt-4 mb-8 rounded-2xl p-0 w-[588px]">
            <CardContent className="p-0">
              {isLoading && !video && <Box className="p-4 flex justify-center"><LinearProgressWithLabel value={progress} /></Box>}
              {!isLoading && video && (
                <video
                  autoPlay
                  loop
                  muted
                  poster={video.poster}
                >
                  <source
                    src={video.url}
                    type={video.type}
                  />
                </video>
              )}
              {!isLoading && !video && <Box className="p-4 text-white text-center flex flex-col gap-y-4 justify-center">
                  {t('general.video-not-found')}
                  <Button variant='outlined' className='text-white border-white rounded-xl w-fit mx-auto' component={Link} to="/">
                    {t('general.go-back')}
                  </Button>
                </Box>}
            </CardContent>
          </Card>
          {!isLoading && video && (
            <Box>
              <Typography variant="h5" className="text-center mt-4 text-white font-bold">
                {video.name}
              </Typography>
              <Typography variant="body2" className="text-center text-gray-400 font-medium">
                {video.size} - {video.duration} seconds
              </Typography>
              <Stack direction="row" spacing={2} className="flex mt-4 items-center mx-auto justify-center">
                <Button
                  variant='outlined'
                  startIcon={<IconShare />}
                  className='w-48 text-white border-white rounded-xl'
                  onClick={() => {}}
                >
                  {t('create.share')}
                </Button>
              </Stack>
              <Card className='mt-12 max-w-lg mx-auto mb-4 rounded-xl' sx={{ backgroundColor: blues[500] }}>
                <CardContent className="grid grid-cols-3">
                  <EnfluLogo className='h-40 w-6/12 mx-auto'/>
                  <Box className="col-span-2 flex flex-col items-start">
                    <Typography variant="h5" className="text-center mt-4 mb-4 text-white">{t('create.create-more')}</Typography>
                    <Typography variant="body1" className="text-center mb-8 text-white">{t('create.create-more-description')}</Typography>
                    <WhitePurpleButton color="primary" variant="contained">
                      {t('menu.upgrade-now')}
                    </WhitePurpleButton>
                  </Box>
                </CardContent>
                </Card>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PublicSharePage;