import { IconLock } from 'assets';
import { blues, gradients, greyIsh } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Star as StarIcon } from '@mui/icons-material';
import {
    Box, ButtonBase, Card, CardContent, IconButton, Stack, Tooltip, Typography
} from '@mui/material';
import { Language } from '@types';

interface LanguageCardProps extends Language {
  hasStar?: boolean;
  selected?: boolean;
  onClick?: (language: Language) => void;
}

const LanguageCard: FC<LanguageCardProps> = ({
  name,
  code,
  hasStar,
  flag,
  locked,
  selected = false,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <ButtonBase
      className="w-full m-2 h-24 max-w-60 justify-center rounded-[20px]"
      sx={{
        background: selected ? gradients.blues : blues[500],
      }}
      disabled={locked}
      onClick={() => onClick && onClick({ name, code, flag, locked })}
    >
      <Card
        className="w-11/12 rounded-[20px] shadow-none"
        sx={{ background: "transparent" }}
      >
        <CardContent className="h-full grid grid-cols-3 gap-x-1 items-center my-auto px-0">
          <Box className="items-center">{flag}</Box>
          <Typography
            variant="subtitle1"
            className="justify-self-start"
            sx={{ color: selected ? greyIsh[100] : greyIsh[200] }}
          >
            {name}
          </Typography>
          <Stack direction="column" spacing={1} className="justify-self-end">
            {hasStar && (
              <Tooltip title={t("language.toggle-star-button")}>
                <IconButton className="h-10 w-10">
                  <StarIcon className="col-span-1 text-white h-8 w-auto" />
                </IconButton>
              </Tooltip>
            )}
            {locked && (
              <Tooltip title={t("language.toggle-lock-button")}>
                <IconButton className="h-10 w-10">
                  <IconLock className="col-span-1 text-white h-8 w-auto" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default LanguageCard;
