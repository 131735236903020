import { useContext } from 'react';
import AuthContext, { AuthContextType } from 'store/auth.context';

const useAuth = () : AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default useAuth;