import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language, MenuType } from "@types";

import type { EnfluState } from "../store";

interface Config {
  language: Language;
  menu: MenuType;
}

const initialState: Config = {
  language: {
    name: "English",
    code: "en",
  },
  menu: {
    open: true,
  },
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
      return state;
    },
    clearLanguage: () => initialState,
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.menu.open = action.payload;
      } else {
        state.menu.open = !state.menu.open;
      }
      return state;
    },
  },
});

export const { setLanguage, clearLanguage, toggleMenu } = configSlice.actions;
export const selectLanguage = (state: EnfluState) => state.config.language;
export const selectMenu = (state: EnfluState) => state.config.menu;
export default configSlice.reducer;
