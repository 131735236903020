import { IconNotification } from 'assets';
import useAuth from 'hooks/useAuth.hook';
import { CreateActions, PaperContainer, Uploader } from 'pages/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { VideoService } from 'services';
import { useDraft } from 'store/enflu.context';
import { useEnfluDispatch, useEnfluSelector } from 'store/hooks';
import { selectAudio } from 'store/reducers/audioSlice';
import { selectLanguage } from 'store/reducers/languageSlice';
import { selectVideo, setVideo } from 'store/reducers/videoSlice';
import { waait } from 'utils';

import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { SnackbarConfig, UploaderStatus } from '@types';

const PreviewPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useEnfluDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { draft, setDraft } = useDraft();
  const [status, setStatus] = useState<UploaderStatus>(UploaderStatus.loading);
  const [progress, setProgress] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>();
  const video = useEnfluSelector(selectVideo);
  const audio = useEnfluSelector(selectAudio);
  const language = useEnfluSelector(selectLanguage);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
    severity: 'success',
  });
  const error = "This is an error message";

  const startVoiceCloning = async (): Promise<File | null> => {
    if (!Object.keys(video).length || !draft || !token) {
      navigate("/create/upload");
      return new Promise((resolve, reject) => {
        reject("Error");
      });
    }

    const formData = new FormData();
    formData.append("file", draft);
    formData.append("name", video.name);
    formData.append("mode", "automatic");
    formData.append("source_language", "en");
    formData.append("target_language", language.code.split("-")[0]);
    formData.append("num_speakers", "1");
    formData.append("watermark", "true");

    setSnackbarConfig({
      open: true,
      message: t("create.preview.video-cloning-started"),
      severity: "info",
    });

    const responsePostVideoCloning = await VideoService.startVoiceCloning(formData, token);

    if (responsePostVideoCloning.status !== 200) {
      return new Promise((resolve, reject) => {
        reject({
          message: "Error uploading video or starting voice cloning",
        });
      });
    }

    
    setProgress(10);
    await waait(500);

    setSnackbarConfig({
      open: true,
      message: t("create.preview.video-cloning-in-progress"),
      severity: "info"
    });

    const { dubbingId } = responsePostVideoCloning.data;
    const responseGetVideoCloning = await VideoService.getVoiceCloningVideo(dubbingId, language.code.split("-")[0], token);
    const { data: videoCloning, status: statusGetVideoCloning } = responseGetVideoCloning;

    if (statusGetVideoCloning !== 200 || !videoCloning) {
      return new Promise((resolve, reject) => {
        reject({
          message: "Error getting video cloning",
        });
      });
    }

    const file = new File([videoCloning], video.name, {
      type: "video/mp4",
    });

    return file;
  };

  const convertVideoToAudio = async (videoFile: File): Promise<File | null> => {
    if (!token) {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
    const response = await VideoService.convertVideoToAudio(videoFile, token);
    const { data, status } = response;
    if (status !== 200 || !data) {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }

    const name = videoFile.name.split(".")[0];
    const file = new File([data], `${name}.mp3`, {
      type: "audio/mp3",
    });

    return file;
  };

  const startLipSync = async (
    video: File,
    audio: File
  ): Promise<File | null> => {

    if (!video || !audio || !token) {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }

    const formData = new FormData();
    formData.append("videoFile", video);
    formData.append("audioFile", audio);
    formData.append("synergize", "true");
    formData.append("model", "wav2lip++");

    const responsePostLipSync = await VideoService.startLipSync(formData, token);

    if (responsePostLipSync.status !== 200 || !token) {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
    await waait(500);
    const { lipSyncId } = responsePostLipSync.data;

    const responseGetLipSync = await VideoService.getLipSyncVideo(lipSyncId, token);
    const { data: videoLipSync, status: statusGetLipSync } = responseGetLipSync;

    if (statusGetLipSync !== 200 || !videoLipSync) {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }

    const file = new File([videoLipSync], video.name, {
      type: "video/mp4",
    });

    return file;
  };

  
  const main = async (countTries = 0) => {
    try {
      setStatus(UploaderStatus.loading);
      const videoVoiceCloning = await startVoiceCloning();
      if (videoVoiceCloning) {
        setSnackbarConfig({
          open: true,
          message: t("create.preview.video-cloning-success"),
          severity: "success",
        });
        setProgress(30);
        await waait(500);
        // Convert video to audio
        const audio = await convertVideoToAudio(videoVoiceCloning);
        if (audio) {
          setSnackbarConfig({
            open: true,
            message: t("create.preview.audio-conversion-success"),
            severity: "success",
          });
          setProgress(60);
          await waait(500);
          const videoLipSync = await startLipSync(videoVoiceCloning, audio);
          if (videoLipSync) {

            setSnackbarConfig({
              open: true,
              message: t("create.preview.lip-sync-success"),
              severity: "success",
            });

            setProgress(100);
            setDraft(null);

            setVideoUrl(URL.createObjectURL(videoLipSync));

            dispatch(
              setVideo({
                ...videoLipSync,
                url: URL.createObjectURL(videoLipSync),
              })
            );
            setStatus(UploaderStatus.success);
          }
        }
      }
    } catch (error) {
      setStatus(UploaderStatus.error);
    }
  };

  // Upload video to the server on component mount
  useEffect(() => {
    main();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Typography variant="h5" className="text-center mt-4 mb-4 text-white">
        {t("signup.finalized")}
      </Typography>
      <Typography variant="body1" className="text-center mb-8 text-white">
        {t("signup.finalized-description")}
      </Typography>
      <PaperContainer status={status}>
        <Uploader
          status={status}
          setStatus={setStatus}
          file={video}
          error={error}
          progress={progress}
          loop={false}
          disabledDropzone
          onClickAfterError={() => main()}
        />
      </PaperContainer>
      {status === UploaderStatus.success && (
        <Alert
          icon={<IconNotification fontSize="inherit" />}
          severity="success"
          className="lg:w-[589px] mx-auto rounded-xl mt-4"
        >
          {t("create.preview.success-message")}
        </Alert>
      )}
      <CreateActions
        hasNextButton
        nextButtonProps={{
          disabled: status !== UploaderStatus.success,
          url: "/create/finalize",
          buttonStr: "create.next",
        }}
      />
      <Box className="mt-12 lg:w-[589px] mx-auto mb-4">
        <Typography
          variant="caption"
          className="mb-16 text-center"
          color="primary.contrastText"
        >
          {t("signup.your-freebee-account-description")}
        </Typography>
        <Button
          variant="text"
          component={Link}
          to=""
          sx={{ color: "primary.light" }}
          className="mt-0 w-full normal-case font-normal text-center mx-auto underline"
        >
          {t("create.find-out-perks-on-upgrade")}
        </Button>
      </Box>
      <Snackbar
        open={snackbarConfig.open}
        autoHideDuration={3000}
        onClose={() => setSnackbarConfig({ ...snackbarConfig, open: false })}
      >
        <Alert severity={snackbarConfig.severity}>{snackbarConfig.message}</Alert>
      </Snackbar>
      </Box>
  );
};

export default PreviewPage;
