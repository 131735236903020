import React, { createContext, FC, ReactNode, useState } from 'react';

import { UserSession } from '@types';

export interface AuthContextType {
  isLogged: boolean;
  token: string | null;
  setToken: (token: string | null) => void;
  setSession: (userSession: UserSession) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [isLogged, setIsLogged] = useState<boolean>(!!token);

  const setSession = (userSession: UserSession) => {
    if (userSession.token) {
      localStorage.setItem('token', userSession.token);
      setToken(userSession.token);
      setIsLogged(true);
    }
  };

  return (
    <AuthContext.Provider value={{ isLogged, token, setToken, setSession }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;