import { IconLangFilter, IconShare, IconUpload } from 'assets';
import { gradients } from 'config/theme/theme';
import { FC } from 'react';

import { Box, Card, CardContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

interface HomeStep {
  title: string;
  description: string;
  icon: React.ComponentType<any>;
}

interface TransparentGlassCardProps {
  step: HomeStep;
  index: number;
}

const stepsList: HomeStep[] = [
  {
    title: "Upload your video",
    description:
      "Easily upload your video directly from your device or  by pasting a link.",
    icon: IconUpload,
  },
  {
    title: "Select languages",
    description:
      "Choose your desired language(s) you want to translate your video to",
    icon: IconLangFilter,
  },
  {
    title: "Save and share",
    description:
      "Download a copy to your device or share it directly with the world.",
    icon: IconShare,
  },
];

export const TransparentGlassCard: FC<TransparentGlassCardProps> = ({
  step,
  index,
}) => (
  <Card
    className="relative rounded-3xl overflow-hidden shadow-lg md:min-h-96"
    sx={{
      background: gradients.cardGlass,
    }}
  >
    <Box className="card-glass absolute top-0 left-0 w-full h-full">
      <Box className="absolute -top-[1px] -left-[1px] w-6 h-6 rounded-ss-full border border-enflu-purple-200 border-r-0 border-b-0" />
      <Box className="absolute -top-[1px] -right-[1px] w-6 h-6 rounded-se-full border border-enflu-blue-400 border-l-0 border-b-0" />
      <Box className="absolute -bottom-[1px] -left-[1px] w-6 h-6 rounded-es-full border border-enflu-purple-200 border-r-0 border-t-0" />
      <Box className="absolute -bottom-[1px] -right-[1px] w-6 h-6 rounded-ee-full border border-enflu-blue-400 border-l-0 border-t-0" />
    </Box>
    <CardContent className="flex flex-col gap-y-4 md:min-h-[500px]">
      <Typography
        variant="h3"
        className="text-9xl md:text-8xl font-semibold font-inter text-enflu-purple-100 mt-4"
      >
        0{index + 1}
      </Typography>
      <Typography variant="h5" className="text-2xl font-inter text-enflu-purple-100 font-medium">
        {step.title}
      </Typography>
      <Typography variant="body1" className="text-xl md:text-lg font-inter text-white mb-8 md:mb-auto">
        {step.description}
      </Typography>
    </CardContent>
    <Box
      className="hidden md:flex absolute bottom-0 left-1/2 transform -translate-x-1/2 rounded-t-full w-full h-40 justify-center items-center"
      sx={{ background: gradients.full }}
    >
      <step.icon className="w-[300px] h-[50px] text-enflu-purple-100" />
    </Box>
  </Card>
);

const HomeStepsList: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      direction={`${isMobile ? 'column' : 'row'}`}
      spacing={3}
      className="mt-32 md:mt-16 justify-center max-w-[90%] md:max-w-[70%] mx-auto"
    >
      {stepsList.map((step, index) => (
        <TransparentGlassCard key={index} step={step} index={index} />
      ))}
    </Stack>
  );
};

export default HomeStepsList;
