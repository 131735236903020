import { ReactComponent as EnfluLogo } from 'assets/logos/enflu_dark_logo.svg';
import { BackButton } from 'pages/common/create-actions/CreateActions';
import { menuItems } from 'pages/common/create-tabs/CreateTabs';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEnfluSelector } from 'store/hooks';

import { Box } from '@mui/material';

import { CreateTabs, Menu } from '../common';

interface CreatePageProps {
  children?: React.ReactNode;
  tab?: number;
  nav: number;
};

const CreatePage : FC<CreatePageProps> = ({ children, tab, nav }) => {
  const { t } = useTranslation();
  const tMenu = useEnfluSelector((state) => state.config.menu);
  const [openMenu, setOpenMenu] = useState<boolean>(tMenu.open);
  return (
    <Box sx={{ bgcolor: 'background.default' }} className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full">
      <Menu nav={nav} open={openMenu} setOpen={setOpenMenu} />
      <Box className={`w-[808px] transition-all ease-in-out ${openMenu ? 'ml-[300px]' : 'ml-40'}`}>
        <Box className="mx-auto flex justify-center">
          <Link to="/"><EnfluLogo className="mx-auto" /></Link>
        </Box>
        <Box className="relative">
          {(tab && tab > 0) ? <BackButton url={menuItems(t)[tab - 1].path} className="absolute top-0 left-0 transform z-20" hasArrow /> : null}
          <CreateTabs tab={tab} />
        </Box>
        <Box className="mt-8">
          {children}
        </Box>
      </Box>
    </Box>
  )
};
export default CreatePage;