import { IconPlay } from 'assets';
import { gradients } from 'config/theme/theme';
import { FC, useEffect, useRef, useState } from 'react';

import { CardContent, IconButton } from '@mui/material';
import { Video } from '@types';

interface CardVideoContentProps {
  video: Video;
  preload: "auto" | "metadata" | "none";
  controls: boolean;
}

const CardVideoContent: FC<CardVideoContentProps> = ({ video, preload, controls }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState<boolean>(controls);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    setShowControls(controls);
  }, [controls]);

  // This function shows and hides the video play button
  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);

      // Activate controls once the user clickes once
      setShowControls(true);
    }
  };

  return (
    <CardContent className="p-0 shadow-lg relative">
      <video ref={videoRef} loop controls={showControls} poster={video.poster} className="w-full" preload={preload}>
        <source src={video.url} type="video/mp4" />
      </video>
      {!isPlaying && (
        <IconButton onClick={togglePlay} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" sx={{ background: gradients.blues }}>
          <IconPlay className="text-white" />
        </IconButton>
      )}
    </CardContent>
  )
}

export default CardVideoContent;
