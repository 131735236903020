import { IconInfo } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { gradients } from 'config/theme/theme';
import { FC } from 'react';

import {
    alpha, Box, Button, Card, CardContent, Chip, IconButton, styled, Tooltip, Typography
} from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

interface Feature {
  icon: React.ComponentType<any>;
  title: string;
  hasInfo?: boolean;
  info?: string;
}

interface PlanCardProps {
  highlight?: boolean;
  type: string;
  title: string;
  buttonText: string;
  features: Feature[];
  notIncludes: Feature[];
  billed?: string;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.common.black, .7),
    padding: '20px 10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
  },
}));

const PlanCard: FC<PlanCardProps> = ({
  highlight,
  type,
  title,
  buttonText,
  features,
  notIncludes,
  billed,
}) => (
  <Card
    className="relative rounded-3xl overflow-hidden shadow-lg min-h-72 w-full"
    sx={{
      background: highlight ? gradients.blues : gradients.cardGlass,
    }}
  >
    <Box className="card-pricing-glass absolute top-0 left-0 w-full h-full">
      <Box className="absolute -top-[1px] -left-[1px] w-6 h-6 rounded-ss-full border border-white border-r-0 border-b-0" />
      <Box className="absolute -top-[1px] -right-[1px] w-6 h-6 rounded-se-full border border-white border-l-0 border-b-0" />
      <Box className="absolute -bottom-[1px] -left-[1px] w-6 h-6 rounded-es-full border border-enflu-grey-500 border-r-0 border-t-0" />
      <Box className="absolute -bottom-[1px] -right-[1px] w-6 h-6 rounded-ee-full border border-enflu-grey-500 border-l-0 border-t-0" />
    </Box>
    <CardContent className="flex flex-col gap-y-4 min-h-72">
      <Typography
        variant="h3"
        className="font-semibold text-enflu-blue-100 mt-4 text-xl text-left"
      >
        {type}
      </Typography>
      {
        highlight 
          ? (
            <Typography variant="h2" className="text-4xl text-white font-medium text-left">
              {title}
            </Typography>
            )
          : (
            <>
              <Box className="flex flex-row items-start">
                <Typography variant="h2" className="text-7xl font-inter text-white font-semibold text-left">
                  {title}
                </Typography>
                <Typography component="span" className="text-white font-inter font-semibold">/mo</Typography>
              </Box>
            </>
          ) 
      }
      {highlight 
        ? <WhitePurpleButton className="text-base normal-case">{buttonText}</WhitePurpleButton>
        : <Button variant="contained" className="text-base normal-case text-white bg-enflu-grey-400">{buttonText}</Button>}

      <Box className="flex flex-col">
        {features.map((feature, index) => (
          <Box key={index} className="flex items-center gap-x-2">
            <feature.icon className="text-enflu-blue-100 text-sm w-[15px]"  />
            <Typography variant="body2" className="text-sm text-left text-white font-normal">
              {feature.title}
            </Typography>
            {feature.hasInfo && (
              <BootstrapTooltip title={feature.info} placement="right" >
                <IconButton onClick={() => {}} className="self-start" >
                  <IconInfo className="text-enflu-blue-100" />
                </IconButton>
              </BootstrapTooltip>
            )}
          </Box>
        ))}
        {notIncludes.map((feature, index) => (
          <Box key={index} className="flex items-center gap-x-2 opacity-40">
            <feature.icon className="text-enflu-blue-100 text-sm w-[15px]"  />
            <Typography variant="body2" className="text-sm text-left text-white font-normal">
              {feature.title}
            </Typography>
            {feature.hasInfo && (
              <BootstrapTooltip title={feature.info} placement="right" >
                <IconButton onClick={() => {}} className="self-start" >
                  <IconInfo className="text-enflu-blue-100" />
                </IconButton>
              </BootstrapTooltip>
            )}
          </Box>
        ))}
        { billed 
          ? (
            <Box className="mt-8">
              <Chip label={billed} className="font-inter text-white bg-enflu-grey-400" />
            </Box>
          ) 
          : null
        }
      </Box>
    </CardContent>
  </Card>
);

export default PlanCard;