import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Audio } from '@types';

import type { EnfluState } from "../store";

const initialState: Audio = {
  name: "Example.mp3",
  type: "audio/mp3",
  duration: 120,
  url: "https://assets.codepen.io/6093409/river.mp3",
  size: 23 * 1024 * 1024,
  lastModified: new Date().getTime(),
  webkitRelativePath: "",
  arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
  slice: () => new Blob(),
  stream: () => new ReadableStream(),
  text: () => Promise.resolve("File content as string"),
};

export const audioSlice = createSlice({
  name: "audio",
  initialState: ({} as Audio) || null,
  reducers: {
    setAudio: (state, action: PayloadAction<Audio>) => {
      const audio = action.payload as Audio;
      return audio;
    },
    clearAudio: () => initialState,
  },
});

export const { setAudio, clearAudio } = audioSlice.actions;
export const selectAudio = (state: EnfluState) => state.audio;
export default audioSlice.reducer;
