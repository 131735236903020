import { EnfluLogo, IconArrowDown } from 'assets';
import { CustomTab, CustomTabs } from 'config/theme';
import { gradients } from 'config/theme/theme';
import { TFunction } from 'i18next';
import Footer from 'pages/common/footer/Footer';
import ExpandReach from 'pages/home/expand-reach/ExpandReach';
import HomeHeader from 'pages/home/header/HomeHeader';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    Accordion, AccordionDetails, AccordionSummary, Box, Chip, Stack, Typography, useMediaQuery
} from '@mui/material';

import PlanCard from './plans/PlanCard';
import { monthlyPlans, yearlyPlans } from './plans/pricing-plans';

interface PricingNavBarTypes {
  title: string;
  discount: number;
}

const pricingNavBarItems = (t: TFunction): PricingNavBarTypes[] => {
  return [
    {
      title: t("pricing.monthly"),
      discount: 0,
    },
    {
      title: t("pricing.annually"),
      discount: 20,
    },
  ];
};

const Pricing: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [pricingTabActive, setPricingTabActive] = useState<number>(0);
  const [accordeonExpanded, setAccordeonExpanded] = useState<boolean[]>(
    Array.from({ length: 10 }).map((_, index) => {
      if (index === 0) return true;
      return false;
    })
  );

  return (
    <Box className="w-full h-full">
      {!isMobile && <HomeHeader navBarSelectionIndex={1} />}
      <Box
        className="pt-20 md:pt-60 px-10 relative w-full h-auto pb-16"
        sx={{ background: gradients.home }}
      >
        {isMobile && (
        <Box className="pb-8 flex flex-col gap-y-8">
          <Link to="/"><EnfluLogo className="w-32 h-32 mx-auto" /></Link>
          <HomeHeader navBarSelectionIndex={1} />
        </Box>
        )}
        <Box className="flex flex-col mx-auto text-center md:max-w-3xl justify-center gap-y-8">
          <Typography
            variant="h1"
            className="text-[1.5rem] md:text-[2rem] font-bold font-inter text-white"
          >
            {t("pricing.simple-transparent-flexible")}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            className="-mt-8"
          >
            <Typography
              variant="h2"
              className="text-[1.5rem] md:text-[2rem] font-normal font-inter text-white"
            >
              {t("pricing.all-plans-start-with-a")}{" "}
            </Typography>
            <Typography
              variant="h2"
              className="text-[1.5rem] md:text-[2rem] font-normal font-inter text-enflu-purple-100"
            >
              {t("pricing.free-trial")}
            </Typography>
          </Stack>
          <Box className="relative mt-8">
            <CustomTabs
              value={pricingTabActive}
              className="flex justify-center mx-auto rounded-lg ml-auto"
              sx={{
                background: gradients.homeTabsBg,
              }}
              onChange={() =>
                setPricingTabActive(pricingTabActive === 0 ? 1 : 0)
              }
            >
              {pricingNavBarItems(t).map((item, index) => (
                <CustomTab
                  key={index}
                  label={
                    <Box className="flex flex-row gap-x-4 items-center">
                      <Typography
                        variant="body1"
                        className="text-white font-inter font-semibold"
                      >
                        {item.title}
                      </Typography>
                      {item.discount > 0 && (
                        <Chip
                          variant="outlined"
                          label={`-${item.discount}%`}
                          className="text-white"
                        />
                      )}
                    </Box>
                  }
                  className="px-4 py-2 mx-2 rounded-lg"
                  sx={{
                    background:
                      pricingTabActive === index
                        ? gradients.purples
                        : undefined,
                  }}
                />
              ))}
            </CustomTabs>
          </Box>
        </Box>
        <Box className="mt-16 flex flex-col gap-y-6 md:flex-row gap-x-6 justify-center max-w-[85%] mx-auto">
          {pricingTabActive === 0
            ? monthlyPlans(t).map((plan, index) => (
                <PlanCard key={index} {...plan} />
              ))
            : yearlyPlans(t).map((plan, index) => (
                <PlanCard key={index} {...plan} />
              ))}
        </Box>
      </Box>
      <Box className="bg-enflu-grey-400 pb-16">
        <Box className="pt-32 flex flex-col mx-auto text-center max-w-3xl justify-center gap-y-8">
          <Typography variant="h1" className="text-3xl font-bold text-white">
            {t("pricing.faqs.title")}
          </Typography>
        </Box>
        <Box className="flex flex-col items-center justify-center mt-16">
          {Array.from({ length: 10 }).map((_, index) => (
            <Accordion
              className="bg-transparent w-10/12 md:w-1/2 rounded-xl mt-2"
              sx={{
                "&:before": {
                  content: "none",
                },
              }}
              expanded={accordeonExpanded[index]}
              onClick={() => {
                let newAcc = [...accordeonExpanded];
                newAcc[index] = !accordeonExpanded[index];
                setAccordeonExpanded(newAcc);
              }}
            >
              <AccordionSummary
                expandIcon={<IconArrowDown />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="body1"
                  className="text-base font-bold text-enflu-blue-100"
                >
                  {t(`pricing.faqs.question${index + 1}`)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" className="font-normal text-white">
                  {t(`pricing.faqs.answer${index + 1}`)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box className="flex flex-row gap-x-2 mt-8">
            <Typography
              variant="body1"
              className="text-base font-bold text-enflu-blue-100"
            >
              {t("pricing.cant-find-answer")}
            </Typography>
            <Link
              to="/contact"
              className="text-enflu-purple-100 normal-case underline font-bold"
            >
              {t("pricing.contact-us")}
            </Link>
          </Box>
        </Box>
        <ExpandReach />
      </Box>
      <Footer />
    </Box>
  );
};

export default Pricing;
