import { IconDelete, IconDuplicate, IconUpgrade } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { blues } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box, ButtonBase, Card, CardContent, Chip, IconButton, Tooltip, Typography
} from '@mui/material';
import { Project } from '@types';

interface DraftsPageProps {
  projects?: Project[];
  duplicateProject?: (project: Project) => void;
  deleteProject?: (project: Project) => void;
}

interface CardDraftProps {
  project: Project;
  duplicateProject?: (project: Project) => void;
  deleteProject?: (project: Project) => void;
}

const CardDraft : FC<CardDraftProps> = ({ project, duplicateProject, deleteProject }) => {
  return (
    <ButtonBase className="rounded-xl">
      <Card className="bg-transparent rounded-xl min-w-60">
        <CardContent className="flex flex-col">
          <Typography variant="h6" className="text-white font-bold text-left">{project.title}</Typography>
          <Typography variant="body2" className="text-white font-light text-left">{project.description}</Typography>
          <Box className="flex flex-row mt-8 justify-between">
            {project.tags.map(tag => (<Chip key={tag.value} label={tag.label} className="text-white font-bold" />))}
            <Box className="ml-2">
              <Tooltip title="Duplicate" arrow>
                <IconButton className="text-white" onClick={() => duplicateProject && duplicateProject(project)}>
                  <IconDuplicate />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton className="text-white" onClick={() => deleteProject && deleteProject(project)}>
                  <IconDelete />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

const DraftsPage : FC<DraftsPageProps> = ({ projects, duplicateProject, deleteProject }) => {
  const { t } = useTranslation();
  return (
    <Box className="mt-8">
      <Box className="justify-start w-full my-4 sm:mb-0 flex flex-wrap gap-4">
        {projects?.map((project, index) => (
          <CardDraft key={index} project={project} duplicateProject={duplicateProject} deleteProject={deleteProject} />
        ))}
      </Box>

      <Card className='mt-12 max-w-lg mx-auto mb-4 rounded-xl' sx={{ backgroundColor: blues[500] }}>
        <CardContent className="grid grid-cols-3">
          <IconUpgrade className='h-40 w-6/12 mx-auto'/>
          <Box className="col-span-2 flex flex-col items-start">
            <Typography variant="h5" className="text-center mt-4 mb-4 text-white">{t('create.create-more')}</Typography>
            <Typography variant="body1" className="text-center mb-8 text-white">{t('create.create-more-description')}</Typography>
            <WhitePurpleButton color="primary" variant="contained">
              {t('menu.upgrade-now')}
            </WhitePurpleButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DraftsPage;