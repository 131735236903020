import { EnfluLogo } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { blues } from 'config/theme/theme';
import Footer from 'pages/common/footer/Footer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Star } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';

const EmailConfirmation : React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  return (
    <Box
      className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full"
    >
      <Box className="pt-20 md:pt-8">
        <Box className="pb-8 gap-y-8 flex flex-col">
          <EnfluLogo className="w-32 h-32 mx-auto" />
        </Box>
      </Box>
      <Box className="bg-none p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto">
        <Typography
          variant="h2"
          className="font-inter text-white text-center mt-2 text-4xl md:text-3xl font-semibold tracking-tight leading-tight"
        >
          {t('auth.almost-there')}
        </Typography>
      </Box>
      <Paper className="bg-none bg-enflu-grey-400 p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl">
        <Stack direction="column">
          <CheckCircleIcon className="font-inter font-medium text-6xl text-center my-4 mx-auto" style={{ color: blues[100] }} />
          <Typography variant="h5" className="text-center text-white font-inter">{t('signup.your-account-created-title')}</Typography>
          <Typography variant="body1" className="text-center text-white font-light">{t('signup.your-account-created-text')}</Typography>
          <Stack direction="row" spacing={2} className="my-6 justify-center">
            <Button 
                variant="outlined" 
                className="transition duration-200 text-white border-white" 
                color="secondary"
                component={Link}
                to="/signin"
                >
                {t('auth.login')}
              </Button>
          </Stack>
        </Stack>
      </Paper>

      <Box className="shadow-none mt-8 mb-12 py-8 px-12 sm:min-h-auto border border-dashed rounded-lg md:min-w-[40em]">
        <Grid container direction="column" spacing={2} className="w-[24rem]">
          <Grid item container direction="row" alignItems="center" spacing={1}>
            <Grid item xs={2}>
              <Star className='text-white'/>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" className="font-medium text-white">{t('general.our-subscription-plans')}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <Typography variant="body2" className="font-normal max-w-lg text-white">
                {t('signup.subscription-plans-description')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <WhitePurpleButton 
                variant="contained" 
                className="text-enflu-purple-400"
                color="primary"
                onClick={() => navigate('/pricing')}
              >
                {t('signup.learn-more')}
              </WhitePurpleButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer className="w-screen mt-24" />
    </Box>
  );
}

export default EmailConfirmation;
