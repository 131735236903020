import { blues, gradients, reds } from 'config/theme/theme';
import { FC } from 'react';

import { alpha, Box, Paper } from '@mui/material';
import { UploaderStatus } from '@types';

interface PaperContainerProps {
  status: UploaderStatus;
  children: React.ReactNode;
}

const PaperContainer: FC<PaperContainerProps> = ({ status, children }) => {
  return (
    <Box
      sx={{
        backgroundImage:
          status === UploaderStatus.loading ? gradients.full : blues[500],
      }}
      className="animate-gradient-circle mt-4 p-[1px] rounded-0 sm:rounded-2xl lg:w-[589px] mx-auto"
    >
      <Paper
        sx={{
          backgroundColor:
            status === UploaderStatus.error
              ? alpha(reds[300], 0.2)
              : UploaderStatus.success === status
                ? alpha(blues[100], 0.4)
                : blues[500],
        }}
        className="shadow-none rounded-0 sm:pt-auto sm:min-h-auto sm:rounded-2xl sm:shadow lg:h-80 lg:w-[588px] mx-auto"
      >
        {children}
      </Paper>
    </Box>
  );
};

export default PaperContainer;
