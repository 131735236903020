import { waait } from "./waait";

export const retry = async <T>(
  fn: () => Promise<T>,
  retries: number,
  delay = 30000
): Promise<T> => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (i === retries - 1) {
        throw error;
      }
      console.error(`Retrying... (${i + 1}/${retries})`);
      await waait(delay);
    }
  }
  throw new Error("Max retries reached");
};
