import { EnfluWhiteLogo, IconFacebook, IconInstagram, IconTiktok, IconXtwitter } from 'assets';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

interface Social {
  name: string;
  icon: React.ComponentType<any>;
  url: string;
}

const socials : Social[] = [
  {
    name: "X",
    icon: IconXtwitter,
    url: "https://twitter.com/enfluofficial"
  },
  {
    name: "TikTok",
    icon: IconTiktok,
    url: "https://www.tiktok.com/@enfluofficial"
  },
  {
    name: "Instagram",
    icon: IconInstagram,
    url: "https://www.instagram.com/enfluofficial"
  },
  {
    name: "Facebook",
    icon: IconFacebook,
    url: "https://www.facebook.com/enfluofficial"
  }
];

interface FooterTypes {
  name: string;
  url: string;
}

const footerItemsList : FooterTypes[] = [
  {
    name: "Home",
    url: "/"
  },
  {
    name: "About",
    url: "/about"
  },
  {
    name: "Contact",
    url: "/contact"
  },
  {
    name: "Privacy Policy",
    url: "/privacy-policy"
  },
  {
    name: "Terms of Service",
    url: "/terms-of-service"
  }
];

interface FooterProps {
  className?: string;
}

const Footer : FC<FooterProps> = ({ className }) => {
  return (
    <Box className={`bg-enflu-grey-400 ${className}`}>
      <Box className="w-11/12 flex flex-col md:flex-row mx-auto justify-between items-center">
        <Box className="hidden md:block">
          <EnfluWhiteLogo />
        </Box>
        <Stack className="flex mt-2 md:mt-auto max-w-[80%] md:max-w-full mx-auto flex-wrap items-center flex-row gap-x-4">
          {
            footerItemsList.map((item: FooterTypes, index) => (
              <Button variant="text" key={index} component={Link} to={item.url} className="normal-case font-inter text-white font-semibold">{item.name}</Button>
            ))
          }
        </Stack>
        <Box className="flex flex-row">
          {
            socials.map((social: Social) => (
              <IconButton onClick={() => window.open(social.url, '_blank')}>
                <social.icon />
              </IconButton>
            ))
          }
        </Box>
      </Box>
      <Box className="w-11/12 mx-auto pt-4 pb-12">
        <Typography className="text-white text-center opacity-40 text-sm">© {new Date().getFullYear()}. Enflu.ai</Typography>
      </Box>
    </Box>
  )
}

export default Footer;