import { CreateActions, PaperContainer, Uploader, VideoUrlLoader } from 'pages/common';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEnfluSelector } from 'store/hooks';
import { selectVideo } from 'store/reducers/videoSlice';

import { Box, Button, Typography } from '@mui/material';
import { UploaderStatus } from '@types';

const UploadPage: FC = () => {
  const { t } = useTranslation();
  const video = useEnfluSelector(selectVideo);
  const [status, setStatus] = useState<UploaderStatus>(Object.keys(video).length > 0 ? UploaderStatus.success : UploaderStatus.ready);
  const error = "This is an error message";

  return (
    <>
      <PaperContainer status={status}>
        <Uploader 
          status={status} 
          setStatus={setStatus} 
          file={video}
          error={error}
        />
      </PaperContainer>
      <VideoUrlLoader setStatus={setStatus} />
      <CreateActions 
        hasNextButton={true}
        nextButtonProps={{
          url: "/create/language",
          buttonStr: "create.next",
          className: "font-bold",
          disabled: status !== UploaderStatus.success
        }}
      />
      <Box className='mt-12 max-w-lg mx-auto mb-4'>
        <Typography variant="caption" className="mb-16 text-center leading-none" color="primary.contrastText">
          {t('signup.your-freebee-account-description')}
        </Typography>
        <Button variant="text" component={Link} to="" sx={{ color: "primary.light" }} className="mt-0 w-full normal-case font-normal text-center mx-auto underline">{t('create.find-out-perks-on-upgrade')}</Button>
      </Box>
    </>
  );
};

export default UploadPage;
