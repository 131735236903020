import { gradients } from 'config/theme/theme';
import Footer from 'pages/common/footer/Footer';
import ExpandReach from 'pages/home/expand-reach/ExpandReach';
import HomeHeader from 'pages/home/header/HomeHeader';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, Typography } from '@mui/material';

const PrivacyPolicy : FC = () => {
  const { t } = useTranslation();

  return (
    <Box className="w-full h-full">
        <HomeHeader navBarSelectionIndex={0} />
        <Box
          className="pt-32 md:pt-60 px-10 relative w-full h-auto pb-16 min-h-[9999px]"
          sx={{ background: gradients.home }}
        >
          <Typography variant="h1" className="text-[2rem] font-normal font-inter text-white text-center">
            {t("policy-and-terms.title")}
          </Typography>
          <Typography variant="body1" className="mt-4 text-white text-center">
            {t("policy-and-terms.last-updated") + " " + new Date().toDateString()}
          </Typography>
          <Box className="mt-8">
            {
              Array.from({ length: 3 }).map((_, index) => (
                <Card key={index} className="mt-8 p-8 bg-white bg-opacity-5 rounded-xl">
                  {
                    t(`policy-and-terms.section-${index + 1}.title`) !== "" && (
                      <Typography variant="h5" className="font-inter font-medium text-white">
                        {index +1}. {t(`policy-and-terms.section-${index + 1}.title`)}
                      </Typography>
                    )
                  }
                  {
                    t(`policy-and-terms.section-${index + 1}.description`) !== "" && (
                      <Typography variant="body1" className="mt-4 text-white">
                        {t(`policy-and-terms.section-${index + 1}.description`)}
                      </Typography>
                    )
                  }
                </Card>
              ))
            }
          </Box>
        </Box>
        <Footer />
    </Box>
  );
};

export default PrivacyPolicy;