import { PlatformBG } from 'assets';
import { WhitePurpleButton } from 'config/theme';
import { gradients } from 'config/theme/theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const ExpandReach : FC = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Expand Your reach Section */}
      <Box className="pt-20 flex flex-col mx-auto text-center max-w-3xl justify-center gap-y-8">
          <Typography variant='h3' className="font-inter text-2xl md:text-3xl font-bold text-white">{t('home.expand-reach.title')}</Typography>
          <Typography variant='h5' className="font-inter text-xl md:text-xl font-medium text-white">{t('home.expand-reach.subtitle')}</Typography>
      </Box>
      <Box className="flex flex-col justify-center gap-x-8 mt-16 relative min-h-[500px]" sx={{ background: gradients.homeBottomTransparency }}>
          <Typography variant='h5' className="relative z-10 text-white text-5xl font-semibold text-center">{t('home.expand-reach.get-started')}</Typography>
          <Typography variant='h5' className="relative z-10 text-white text-5xl font-semibold text-center">{t('home.expand-reach.with-enflu')}</Typography>
          <WhitePurpleButton onClick={() => {}} className="relative z-10 mt-8 mx-auto">
              {t('home.expand-reach.try-for-free')}
          </WhitePurpleButton>
          <Typography variant="caption" className='mt-8 text-center text-white text-sm opacity-70'>{t('home.expand-reach.no-credit-card-required')}</Typography>
          <img src={PlatformBG} alt="Background plaftform get started" className="absolute bottom-10 left-1/2 transform -translate-x-1/2 h-auto w-1/2 z-0 opacity-50" />
      </Box>
    </>
  )
}

export default ExpandReach;