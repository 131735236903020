import { CustomTab, CustomTabs } from 'config/theme';
import { gradients } from 'config/theme/theme';
import CardVideo from 'pages/common/video/CardVideo';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Box, Collapse, List, ListItemButton, ListItemText, Typography, useMediaQuery, useTheme
} from '@mui/material';
import { Video } from '@types';

interface EnfluInUseTypes {
  title: string;
  video: Video;
}

const tabsItems: EnfluInUseTypes[] = [
  {
    title: "Content Creators",
    video: {
      url: process.env.REACT_APP_PUBLIC_URL + "/videos/ContentCreators.mp4",
      poster:
        process.env.REACT_APP_PUBLIC_URL +
        "/videos/posters/poster-enflu-01.png",
      name: "Content Creators",
      type: "video/mp4",
      size: 10 * 1024 * 1024,
      lastModified: new Date().getTime(),
      webkitRelativePath: "",
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      slice: () => new Blob(),
      stream: () => new ReadableStream(),
      text: () => Promise.resolve("File content as string"),
    } as Video,
  },
  {
    title: "Educators and online courses",
    video: {
      url:
        process.env.REACT_APP_PUBLIC_URL +
        "/videos/Educators-OnlineCourses.mp4",
      poster:
        process.env.REACT_APP_PUBLIC_URL +
        "/videos/posters/poster-enflu-02.png",
      name: "Educators and online courses",
      type: "video/mp4",
      size: 10 * 1024 * 1024,
      lastModified: new Date().getTime(),
      webkitRelativePath: "",
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      slice: () => new Blob(),
      stream: () => new ReadableStream(),
      text: () => Promise.resolve("File content as string"),
    } as Video,
  },
  {
    title: "Businesses and marketers",
    video: {
      url: process.env.REACT_APP_PUBLIC_URL + "/videos/Business-Marketing.mp4",
      poster: "N/A",
      name: "Businesses and marketers",
      type: "video/mp4",
      size: 10 * 1024 * 1024,
      lastModified: new Date().getTime(),
      webkitRelativePath: "",
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      slice: () => new Blob(),
      stream: () => new ReadableStream(),
      text: () => Promise.resolve("File content as string"),
    } as Video,
  },
  {
    title: "Non-profits and NGOs",
    video: {
      url: process.env.REACT_APP_PUBLIC_URL + "/videos/NGOs.mp4",
      poster: "N/A",
      name: "Non-profits and NGOs",
      type: "video/mp4",
      size: 10 * 1024 * 1024,
      lastModified: new Date().getTime(),
      webkitRelativePath: "",
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      slice: () => new Blob(),
      stream: () => new ReadableStream(),
      text: () => Promise.resolve("File content as string"),
    } as Video,
  },
];

const EnfluInUse: FC = () => {
  const { t } = useTranslation();

  const [enfluInUseTabActive, setEnfluInUseTabActive] = useState<number>(0);
  // For mobile view
  const [openNestedList, setOpenNestedList] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleEnfluInUseChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => setEnfluInUseTabActive(newValue);

  return (
    <Box className="mt-32">
      <Box className="pt-16 md:pt-32 flex flex-col mx-auto text-center max-w-3xl justify-center gap-y-8">
        <Typography
          variant="h3"
          className="font-inter text-3xl font-bold text-white"
        >
          {t("home.enflu-in-use.title")}
        </Typography>
        <Typography
          variant="h5"
          className="font-inter max-w-[95%] mx-auto md:max-w-full text-xl font-medium text-white"
        >
          {t("home.enflu-in-use.subtitle")}
        </Typography>
      </Box>
      <Box className="relative mt-8">
        <CustomTabs
          value={enfluInUseTabActive}
          className="hidden md:flex justify-center mx-auto rounded-lg"
          sx={{
            background: gradients.homeTabsBg,
            "& .MuiTabs-flexContainer": {
              ...(isMobile && {
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "1rem",
              }),
            },
          }}
          onChange={handleEnfluInUseChange}
        >
          {tabsItems.map((tab, index) => (
            <CustomTab
              key={index}
              label={tab.title}
              className="px-4 py-2 mx-2 rounded-lg"
              sx={{
                background:
                  enfluInUseTabActive === index ? gradients.purples : undefined,
              }}
            />
          ))}
        </CustomTabs>
        {isMobile && (
          <List className="font-inter text-xl font-medium text-white">
            <ListItemButton
              className="shadow-lg w-11/12 rounded-lg mx-auto"
              sx={{ background: gradients.homeTabsBg }}
              onClick={() => setOpenNestedList(!openNestedList)}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h5"
                    className="font-inter text-base font-bold text-white"
                  >
                    {tabsItems[enfluInUseTabActive].title}
                  </Typography>
                }
              />
              {openNestedList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {tabsItems.map((item, index) => (
              <Collapse in={openNestedList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={(e) => {
                      handleEnfluInUseChange(e, index);
                      setOpenNestedList(false);
                    }}
                  >
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </List>
              </Collapse>
            ))}
          </List>
        )}
      </Box>
      <Box className="mt-4 md:mt-8 flex justify-center items-center flex-col mx-auto">
        <CardVideo
          key={tabsItems[enfluInUseTabActive].title}
          video={tabsItems[enfluInUseTabActive].video}
          isCentered={false}
          className="card-video-blue w-11/12 md:w-1/2"
          preload={enfluInUseTabActive === 0 ? "metadata" : "auto"}
          controls={false}
        />
        <Typography
          variant="caption"
          className="text-center text-white md:max-w-[50%] mt-4"
        >
          {t("home.enflu-in-use.description")}
        </Typography>
      </Box>
    </Box>
  );
};

export default EnfluInUse;
