import { AboutJoinEnflu, EnfluWhiteLogo, TeamAmer, TeamKhodr, VerticalFullPageBG } from 'assets';
import { gradients } from 'config/theme/theme';
import Footer from 'pages/common/footer/Footer';
import HomeHeader from 'pages/home/header/HomeHeader';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Card, Stack, Typography, useMediaQuery } from '@mui/material';

const AboutPage : FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  return (
    <Box className="w-full h-full">
        {!isMobile && <HomeHeader navBarSelectionIndex={0} />}
        <Box
          className="pt-20 md:pt-60 px-10 relative w-full h-auto pb-16"
          sx={{ background: gradients.fullVerticalPage }}
        >
          <img src={VerticalFullPageBG} alt="Vertical about page" className="absolute z-0 top-0 left-0 mix-blend-color-burn" />
          <Box className="flex flex-col mx-auto text-center md:max-w-3xl justify-center gap-y-8 z-10 md:min-h-[500px]">
            <Link to="/" className="z-10">
              <EnfluWhiteLogo className="w-32 h-32 mx-auto" />
            </Link>
            {isMobile && <HomeHeader navBarSelectionIndex={0} />}
            <Typography variant="h1" className="text-[1.5rem] md:text-[2rem] font-medium font-inter text-white">
              {t("about.title")}
            </Typography>
            <Card className="mt-8 p-8 bg-white bg-opacity-5 rounded-xl">
              <Typography variant="h5" className="font-inter font-medium text-white">
                {t("about.mission")}
              </Typography>
              <Typography variant="body1" className="mt-4 text-white">
                {t("about.mission-description")}
              </Typography>
            </Card>
            <Box className="relative mt-8">
             <Typography variant="h4" className="font-inter font-medium text-white">
               {t("about.founding-team")}
              </Typography>
              <Stack direction={isMobile ? 'column' : 'row'} className="mt-4 gap-y-6 md:gap-x-6">
                <Card className="p-4 rounded-xl min-h-48" sx={{ background: gradients.cardAboutGradient }}>
                  <Box className="flex flex-row justify-center items-center h-full">
                    <Box className="flex flex-col">
                      <Typography variant="h5" className="text-white">{t('about.first-name-1')}</Typography>
                      <Typography variant="body1" className="text-white font-bold">{t('about.last-name-1')}</Typography>
                      <Typography variant="body2" className="text-white mt-6 font-normal">{t('about.description-1')}</Typography>
                    </Box>
                    <img src={TeamAmer} alt="Amer Enflu" className="w-36 -mr-6 -mb-8" />
                  </Box>
                </Card>
                <Card className="p-4 rounded-xl min-h-48" sx={{ background: gradients.cardAboutGradient }}>
                  <Box className="flex flex-row justify-center items-center h-full">
                    <Box className="flex flex-col">
                      <Typography variant="h5" className="text-white">{t('about.first-name-2')}</Typography>
                      <Typography variant="body1" className="text-white font-bold">{t('about.last-name-1')}</Typography>
                      <Typography variant="body2" className="text-white mt-6 font-normal">{t('about.description-1')}</Typography>
                    </Box>
                    <img src={TeamKhodr} alt="Khodr Enflu" className="w-36 -mr-6 -mb-8" />
                  </Box>
                </Card>
              </Stack>
            </Box>
            <Box className="relative mt-32 flex flex-col gap-y-16 md:flex-row md:min-h-96">
              <Box className="w-full md:w-1/2">
                <Typography variant="h4" className="font-inter font-medium text-white text-left">
                  {t("about.join-enflu")}
                </Typography>
                <Typography variant="body1" className="text-white mt-4 text-left">
                  {t("about.join-enflu-description")}
                </Typography>
                <Box className="w-full flex mt-6">
                  <Typography component="a" href="mailto:hello@enflu.ai" className="text-left text-enflu-purple-100 underline">Email us at hello@enflu.ai</Typography>
                </Box>
              </Box>
              <Box className="w-full md:absolute left-1/2 top-0 transform -translate-y-4 ">
                <img src={AboutJoinEnflu} alt='Join Enflu' />
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
    </Box>
  );
};

export default AboutPage;