import { IconAudio, IconLangFilter, IconSync } from 'assets';
import { greyIsh } from 'config/theme/theme';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { alpha, Box, List, ListItem, Tab, Tabs, Typography } from '@mui/material';

interface Feature {
  icon: React.ComponentType<any>;
  title: string;
  content: {
    title: string;
    description: string;
  }[];
}

const featuresList: Feature[] = [
  {
    icon: IconAudio,
    title: "Voice & Emotion Customization",
    content: [
      {
        title: "Personalized Voice Modeling",
        description:
          "We train models exclusively for you, capturing every nuance of your voice with unparalleled accuracy and enhancing output authenticity.",
      },
      {
        title: "Emotion Control",
        description:
          "Fine-tune emotions across your entire video or target specific words or sections, ensuring your message resonates precisely as intended.",
      },
      {
        title: "Text-to-Voice Transformation",
        description:
          "Enter your script and select your desired language. Our advanced AI generates high-quality voice-overs that sound just like you!",
      },
    ],
  },
  {
    icon: IconLangFilter,
    title: "Authenticity & Flexibility",
    content: [
      {
        title: "Native Language Authenticity",
        description:
          "Maintain the integrity of personal and cultural references by preserving specific words in their original language.",
      },
      {
        title: "Accent Flexibility",
        description:
          "Default to the original accent or adapt it to connect more deeply with diverse audiences.",
      },
      {
        title: "Word-Level Editing",
        description:
          "Gain narrative control by editing or replacing specific words as needed.",
      },
    ],
  },
  {
    icon: IconSync,
    title: "Advanced Synchronization & Clarity",
    content: [
      {
        title: "Superior Face Sync Technology",
        description:
          "Outperforms standard translation tools with flawless audio-visual matching, even with voice modifications.",
      },
      {
        title: "Speech Speed Adjustment",
        description:
          "Customize the pace of speech for your entire video or fine-tune specific segments to match your content’s rhythm and audience’s preference.",
      },
      {
        title: "Audio Clarity Enhancement",
        description:
          "We detect and edit unclear or noisy segments in your videos, ensuring a clear and uninterrupted story.",
      },
    ],
  },
];

const Features: FC = () => {
  const { t } = useTranslation();
  const [featuresTabActive, setFeaturesTabActive] = useState<number>(0);

  const handleFeaturesChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => setFeaturesTabActive(newValue);

  return (
    <>
      {/* Features Section */}
      <Box className="pt-32 flex flex-col mx-auto text-center max-w-3xl justify-center gap-y-8">
        <Typography variant="h1" className="text-3xl font-bold text-white">
          {t("home.features.title")}
        </Typography>
      </Box>
      <Box className="flex flex-col sm:flex-row gap-x-14 max-w-[80%] mt-12 mx-auto">
        <Tabs
          orientation="vertical"
          value={featuresTabActive}
          onChange={handleFeaturesChange}
          className="md:w-1/2"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {featuresList.map((feature, index) => (
            <Tab
              icon={<feature.icon className="text-white" />}
              label={feature.title}
              id={`feature-tab-${index}`}
              aria-controls={`feature-tabpanel-${index}`}
              className="flex flex-row gap-x-4 items-center justify-start rounded-2xl text-enflu-purple-100 border-none normal-case max-w-none font-inter text-lg"
              sx={{
                backgroundColor:
                  featuresTabActive === index
                    ? alpha(greyIsh[100], 0.1)
                    : "transparent",
              }}
            ></Tab>
          ))}
        </Tabs>
        <List className="md:w-1/2 m-0 flex flex-col gap-y-4">
          {featuresList[featuresTabActive].content.map((content, index) => (
            <ListItem className="flex flex-col gap-y-2 justify-start items-start">
              <Typography
                variant="h5"
                className="text-base font-inter font-semibold text-white"
              >
                <span className="text-enflu-purple-100">0{index + 1}.</span>{" "}
                {content.title}
              </Typography>
              <Typography
                variant="body2"
                className="font-inter font-light text-white"
              >
                {content.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default Features;
