import { EnfluLogo } from 'assets';
import { CustomTextField, theme as globalTheme } from 'config/theme';
import { blues } from 'config/theme/theme';
import { TFunction } from 'i18next';
import { NextButton } from 'pages/common/create-actions/CreateActions';
import Footer from 'pages/common/footer/Footer';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthService } from 'services';
import { useEnfluDispatch } from 'store/hooks';
import { setUser } from 'store/reducers/userSlice';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    Alert, alpha, Box, MenuItem, Paper, Select, Snackbar, Stack, Typography
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SnackbarConfig, User } from '@types';

type FormFields = {
  email: string;
  name: string;
  password: string;
  repeat_password: string;
  gender: string;
  year_of_birth: string;
};

interface Props {
  email?: string;
  setEmail?: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Form Validation Schema
 */
const schema: (t: TFunction) => yup.ObjectSchema<FormFields> = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("auth.yup-name-required")),
    email: yup
      .string()
      .email(t("auth.yup-email-validation"))
      .required(t("auth.yup-email-required")),
    password: yup
      .string()
      .required(t("signin.please-enter-your-password"))
      .min(12, t("signup.caption12Characters")),
    repeat_password: yup
      .string()
      .oneOf([yup.ref("password"), ""], t("signup.yupPasswordMatch"))
      .required(t("signin.please-repeate-your-password")),
    gender: yup.string().required(t("signup.please-select-gender")),
    year_of_birth: yup.string().required(t("signup.please-select-year-of-birth")),
  });

const defaultValues: FormFields = {
  name: "",
  email: "",
  password: "",
  repeat_password: "",
  gender: "",
  year_of_birth: "",
};

const CreateEmailPassword: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useEnfluDispatch();
  const { control, formState, handleSubmit, setValue } = useForm<FormFields>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema(t)),
  });
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>({
    open: false,
    message: '',
    severity: 'success',
  });
  const { isValid, errors } = formState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(props?.email ? true : false);

  useEffect(() => {
    if (props?.email) {
      setEmailIsValid(true);
      setValue("email", props.email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.email]);

  const onSubmit = async (data: FormFields) => {
    setIsSubmitting(true);
    const newUser : User = {
      name: data.name,
      email: data.email,
      password: data.password
    };

    try {
      const user = await AuthService.signup(newUser);
      dispatch(setUser(user));
      // Simulate API call
      setIsSubmitting(false);
      navigate("/confirm-email-from-inbox", { replace: true });

    } catch (error) {
      setIsSubmitting(false);
      setSnackbarConfig({
        open: true,
        message: t('signup.error-creating-account'),
        severity: 'error'
      });
    }
  };

  return (
    <Box className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:h-full">
      <Box className="pt-20 md:pt-8">
        <Box className="pb-8 gap-y-8 flex flex-col">
          <Link to="/"><EnfluLogo className="w-32 h-32 mx-auto" /></Link>
        </Box>
      </Box>
      <Box className="bg-none p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto">
        <Typography
          variant="h2"
          className="font-inter text-white text-center mt-2 text-4xl md:text-3xl font-semibold tracking-tight leading-tight"
        >
          {t("auth.create-an-account")}
        </Typography>
      </Box>
      <Paper className="bg-none bg-enflu-grey-400 p-4 w-11/12 md:w-1/2 lg:w-96 mx-auto rounded-xl">
        <form
          name="loginForm"
          noValidate
          className="flex flex-col justify-center w-full mt-8 mb-4 sm:mb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.email")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg prefilled"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {field.value?.length ? (
                          <CheckCircleIcon
                            style={{ color: blues[100], padding: "0.2rem" }}
                          />
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.password")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="password"
                  error={!!errors.password}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
                {errors.password && <Typography className="text-red-500 text-sm mt-0">{errors.password.message}</Typography>}
              </Stack>
            )}
          />

          <Controller
            name="repeat_password"
            control={control}
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.repeat-password")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="password"
                  error={!!errors.repeat_password}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
                {errors.repeat_password && <Typography className="text-red-500 text-sm mt-0">{errors.repeat_password.message}</Typography>}
              </Stack>
            )}
          />

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.name")}
                </Typography>
                <CustomTextField
                  {...field}
                  className="text-slate-300 font-light grow w-full rounded-lg"
                  autoFocus
                  type="text"
                  error={!!errors.name}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    color: "text.primary",
                    bgcolor: alpha(globalTheme.blues[200], 0.2),
                    border: "none",
                    "& input": {
                      height: "100%",
                    },
                    "& input::placeholder": {
                      color: "white",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                />
                {errors.name && <Typography className="text-red-500 text-sm mt-0">{errors.name.message}</Typography>}
              </Stack>
            )}
          />

          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.gender")}
                </Typography>
                <Select
                  {...field}
                  labelId="gender-label"
                  id="gender"
                  displayEmpty
                  className="bg-none bg-enflu-gray-400 text-white border-enflu-blue-200 rounded-2xl w-1/2 font-semibold"
                  MenuProps={{
                    classes: { paper: "bg-none bg-enflu-gray-400 text-white" },
                  }}
                  sx={{
                    "& fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                  }}
                >
                  <MenuItem value="">{t('signup.select-gender')}</MenuItem>
                  <MenuItem value="male">{t('signup.male')}</MenuItem>
                  <MenuItem value="female">{t('signup.female')}</MenuItem>
                  <MenuItem value="other">{t('signup.other')}</MenuItem>
                </Select>
                {errors.gender && <Typography className="text-red-500 text-sm mt-0">{errors.gender.message}</Typography>}
              </Stack>
            )}
          />

          <Controller
            name="year_of_birth"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Stack direction="column" spacing={2} className="flex mt-4">
                <Typography
                  variant="body1"
                  className="text-base font-bold"
                  color="common.white"
                >
                  {t("signup.year_of_birth")}
                </Typography>
                <Select
                  {...field}
                  labelId="year_of_birth-label"
                  id="year_of_birth"
                  displayEmpty
                  className="bg-none bg-enflu-gray-400 text-white border-enflu-blue-200 rounded-2xl w-1/2 font-semibold"
                  MenuProps={{
                    classes: { paper: "bg-none bg-enflu-gray-400 text-white" },
                  }}
                  sx={{
                    "& fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3E83E1 !important",
                    },
                  }}
                >
                  <MenuItem value="">{t('signup.select-from-list')}</MenuItem>
                  {Array.from({ length: 100 }, (_, i) => i + 1924).map(
                    (year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    )
                  )}
                </Select>
                {errors.year_of_birth && <Typography className="text-red-500 text-sm mt-0">{errors.year_of_birth.message}</Typography>}
              </Stack>
            )}
          />
          <Typography variant='caption' className="text-white text-center mx-auto mt-4">
            {!isValid && 'Please fill out all required fields.'}
          </Typography>

          <NextButton
            buttonStr="auth.create-an-account"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={isSubmitting}
            className='mb-8 w-2/3 mx-auto'
          />
        </form>
      </Paper>
      <Snackbar
        open={snackbarConfig.open}
        autoHideDuration={3000}
        onClose={() => setSnackbarConfig({ ...snackbarConfig, open: false })}
      >
        <Alert severity={snackbarConfig.severity}>{snackbarConfig.message}</Alert>
      </Snackbar>
      <Footer className="w-screen mt-48" />
    </Box>
  );
};

export default CreateEmailPassword;
