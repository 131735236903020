export interface EnfluFile extends File {
  id?: string | number;
  description?: string;
  url?: string;
}

export enum UploaderStatus {
  ready = "ready",
  loading = "uploading",
  error = "error",
  success = "success",
}

export interface VideoAndAudioResponse {
  id: string;
  createdAt: string;
  status: string;
  videoUrl: string;
  originalVideoUrl: string;
  originalAudioUrl: string;
  synergize: boolean;
  creditsDeducted: number;
  webhookUrl: string;
  errorMessage: string | null;
}

export interface FileTag {
  value: string;
  label: string;
}

export interface Image {
  src: string;
  alt: string;
}

export interface VoiceCloningDubResponse {
  message: string;
  dubbingId: string;
}
export interface LipSyncResponse {
  message: string;
  lipSyncId: string;
}
